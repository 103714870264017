.mrBookingPage {
  margin-top: 0;
  padding: 0 30px;
}
.mrBookingPageIn {
  display: flex;
  justify-content: space-between;
}
.mrBookingRgt {
  width: 25%;
  border-left: solid 1px rgba(0, 0, 0, 0.22);
}
.mrBookingLft {
  width: 75%;
  max-height: calc(100vh - 114px);
  overflow: auto;
  position: relative;
  margin: 0 auto;
}
.mrBookingRgtSticky {
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 0 10px 0 30px;
  position: relative;
}
.BookingStepFormTop input[readonly],
.BookingStepFormTop textarea[readonly] {
  opacity: 1;
  cursor: pointer;
}
.BookingStepAll {
  width: 89%;
  margin: 0 auto;
}
.BookingStepFormTop li:nth-child(2),
.BookingStepFormTop li:nth-child(3) {
  width: 17.5%;
}
.BookingStepFormTop li.fullDayBox {
  width: 11%;
  position: relative;
  top: 27px;
}
.mrBooking {
  width: 80%;
  margin: 0 auto;
}
.pericePerDay {
  position: absolute;
  right: 6px;
  top: 10px;
  text-align: right;
  font-size: 10px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  background: #fff;
  width: auto !important;
}
.complimentaryBox {
  display: flex;
  align-items: center;
}
li.complimentaryBox > div {
  width: 55px !important;
  margin-left: 15px;
  margin-top: 0 !important;
}
li.complimentaryBox > div > div > div {
  margin-top: 0 !important;
  height: 25px !important;
  line-height: 27px !important;
}
.mrBookingStepsLabel > span > svg {
  color: #dfdfdf;
  font-size: 16px;
  display: none;
}
.mrBookingStepsLabel > span > svg.MuiStepIcon-root.MuiStepIcon-active,
.mrBookingStepsLabel > span > svg.MuiStepIcon-root.MuiStepIcon-completed {
  color: #000;
}
.mrBookingStepsLabel > span > svg > text {
  font-size: 0;
}
.mrBookingStepsLabel .MuiStepLabel-labelContainer span {
  margin-top: 6px;
  text-align: center;
  color: #000;
  font-family: 'Varta', sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.mrBookingSteps .MuiStepConnector-alternativeLabel {
  top: 9px;
  left: calc(-50% + 22px);
  right: calc(50% + 22px);
}
.BookingStepFormbtm li:last-child {
  margin-left: 0;
  width: 51.3%;
}
.bookingToatal ul li {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.bookingToatal ul:last-child li {
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #000;
}
.bookingToatal ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0;
}
.pageBredcrumbTimer {
  position: relative;
}
hr.formDivider.fullWidth {
  width: 100%;
  margin: 0 !important;
}
.bookingToatalBtm {
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  background: #fff;
  padding: 0 10px 0 30px;
}
.mrBookingAccordion {
  max-height: 43vh;
  overflow-y: auto;
}
.mrBookingAccordion > .MuiAccordion-root {
  box-shadow: none !important;
  border-bottom: 1px solid #dfdfdf !important;
}
.mrBookingAccordion > .MuiAccordion-root.Mui-expanded {
  margin: 0 0 0;
}
.mrBookingAccordion > .MuiAccordion-root > .MuiAccordionSummary-root {
  min-height: 100%;
}
.mrBookingAccordion
  > .MuiAccordion-root
  > .MuiAccordionSummary-root
  > .MuiAccordionSummary-content {
  margin: 0 0;
}
.mrBookingAccordion > .MuiAccordion-root > .MuiAccordionSummary-expandIcon {
  padding: 0;
}
.MuiAccordion-root:before {
  display: none;
}
.mrBookingAccordion > div > .MuiButtonBase-root.Mui-expanded {
  border-bottom: 1px solid #dfdfdf;
}
.mrBookingAccordion > div > .MuiButtonBase-root {
  padding: 12px 0;
}
.mrBookingAccordion .MuiIconButton-edgeEnd {
  margin-right: 0;
  padding: 0;
}
.mrBookingAccordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: initial !important;
}
.MuiAccordionSummary-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mrBookingAccordion .MuiAccordionDetails-root ul li:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mrBookingAccordion .MuiAccordionSummary-content p,
.headingPrice {
  font-family: 'Varta', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
}
.mrBookingAccordion .MuiAccordionSummary-content .MuiIconButton-label svg {
  fill: #000;
}
.mrBookingAccordion .MuiAccordionDetails-root ul li {
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #000000;
  font-family: 'Varta', sans-serif;
}
.mrBookingAccordion .MuiAccordionDetails-root ul li span {
  color: #838384;
  display: inline-block;
  margin: 0 10px;
  margin-top: -2px;
}
.mrBookingAccordion .MuiAccordionDetails-root ul li img {
  width: 22px;
  margin-top: -6px;
}
.mrBookingAccordion .MuiAccordionDetails-root {
  padding: 15px 0 35px;
}

.mrBookingSteps .mrBookingStepsLabel .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-1.svg) no-repeat;
  top: -6px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 15px;
}
.mrBookingSteps:nth-child(2)
  .mrBookingStepsLabel
  .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-2.svg) no-repeat;
  top: -6px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 13px;
}

.mrBookingSteps:nth-child(3)
  .mrBookingStepsLabel
  .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-3.svg) no-repeat;
  top: -6px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 22px;
}
.mrBookingSteps:nth-child(4)
  .mrBookingStepsLabel
  .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-4.svg) no-repeat;
  top: -3px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 22px;
}
.orderTable {
  box-shadow: none !important;
  border: none;
}
.orderTable table tr th,
.orderTable table tr td {
  border: none;
}
.orderTable table tr {
  border-bottom: 1px solid rgba(151, 151, 151, 0.22);
}
.orderTable table thead tr,
.orderTable table .orderPCont tr:last-child,
.orderTable table .orderPBreakdown tr:last-child {
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.orderTable table tr td {
  font-size: 15px;
  line-height: 19px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  padding: 12px;
}
.orderTable table tr th {
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.orderTable table tr th img {
  display: inline-block;
  vertical-align: middle;
}
.orderTable table tr th:first-child,
.orderTable table tr td:first-child {
  color: #444444;
  width: 64%;
  padding: 12px;
}
.orderTable table .orderPBreakdown tr td {
  font-weight: 500;
  color: #838384;
}
.orderTable table .orderPBreakdown tr:last-child td {
  font-weight: bold;
}
.orderTable table .orderPBreakdown tr.orderDiscount td span {
  color: #444444;
  border-bottom: 1px solid #444444;
}
.orderNotes span {
  display: block;
  margin-top: 5px;
}
.orderNotes {
  padding: 16px;
  font-size: 16px;
  line-height: 20px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.staffNotes span {
  display: block;
  margin-top: 5px;
}
.staffNotes {
  padding: 16px;
  font-size: 16px;
  line-height: 20px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.orderTable table tr th img {
  display: inline-block;
  vertical-align: top;
  width: 22px;
  margin-left: 10px;
  cursor: pointer;
}

.cateringMainTable {
  margin-top: 0;
  border-top: 1px solid rgba(151, 151, 151, 0.22);
  padding-top: 50px;
}

.customerDtls {
  margin-top: 5%;
}
.customerDtls h2 {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #444444;
  font-family: 'Varta', sans-serif;
  padding: 16px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}
.customerDtls p {
  font-size: 15px;
  line-height: 20px;
  color: #444444;
  font-family: 'Varta', sans-serif;
  padding: 20px 16px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.22);
}
.totalDtls {
  margin-top: 5%;
  font-size: 15px;
  font-weight: 600;
  color: #444444;
  font-family: 'Varta', sans-serif;
  padding: 20px 16px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.GuestList div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  /* border-bottom: 1px solid rgba(151, 151, 151, 0.22); */
  height: 70px;
}
.GuestList div span {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #444444;
  font-family: 'Varta', sans-serif;
}
.GuestList .requestguestlistbtn {
  border-radius: 4px;
  width: auto;
  border: 1px solid #000;
  cursor: pointer;
  height: 47px;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
  font-family: Varta, sans-serif;
  font-weight: bold;
  line-height: normal;
  background-color: #000000;
  color: #ffffff;
}
.GuestList div a {
  color: #ffffff;
}
.GuestList textarea {
  height: 150px;
  width: 300px;
  margin-top: 10px;
  padding: 10px;
  font-family: 'Varta', sans-serif;
  outline: none;
  color: #444444;
  font-size: 16px;
}
.GuestList .savebtnforguestlist {
  display: block;
  margin-top: 10px;
  border-radius: 4px;
  width: auto;
  border: 1px solid #000;
  cursor: pointer;
  height: 35px;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
  font-family: Varta, sans-serif;
  font-weight: bold;
  line-height: normal;
  background-color: #000000;
  color: #ffffff;
}

.pageContainer.BookingStepContainer {
  margin-top: 0;
}
.cartItemValue {
  padding: 0px 10px;
  border-radius: 1px;
  border: solid 2px #979797;
  text-align: center;
  margin: 0;
  outline: none;
  height: 25px;
  font-size: 16px;
  line-height: 25px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.cartItemValue input,
.cartItemValue > div {
  height: 100%;
  width: 100%;
  text-align: center;
}
.cartItemValue > div:after,
.cartItemValue > div:before {
  display: none;
}

.cateringContMain {
  display: block;
  width: 100%;
}
.cateringContMain > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.cateringAddCart {
  width: 19%;
}
.cartItemsNo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}
ul.cartItemsNo li {
  width: 32%;
}
.cartItemsNo li button {
  padding: inherit;
  width: 100%;
  min-width: 100%;
  height: 100%;
}

.BookingStepFormAccrd .MuiAccordion-root {
  box-shadow: none;
  margin: 0 0 40px !important;
}
.BookingStepFormAccrd .MuiAccordionSummary-root {
  border-bottom: 2px solid #979797;
  min-height: auto;
  padding: 0 10px 0 0;
}
.BookingStepFormAccrd .cateringAccdnCont > ul > li {
  border: 0.1px solid rgba(151, 151, 151, 0.15);
  margin-top: 3px;
  padding: 15px;
}
.cateringAccdnCont {
  padding: 8px 0 0 !important;
}

.BookingStepFormAccrd .MuiAccordionSummary-root p {
  font-size: 16px;
  line-height: 20px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  text-transform: capitalize;
}
.BookingStepFormAccrd .Mui-expanded .MuiAccordionSummary-root p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.BookingStepFormAccrd .MuiAccordionSummary-content {
  margin: 0 0 0 !important;
}
.BookingStepFormAccrd .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.mrBookingAccordion .MuiAccordionDetails-root ul.CateringMenuCheckout li h4,
.mrBookingAccordion .MuiAccordionDetails-root ul.CateringMenuCheckout li h5 {
  font-weight: normal;
}
.cateringCont h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #000;
  font-family: 'Varta', sans-serif;
  margin-bottom: 5px;
}
.cateringCont h3 span {
  color: #444444;
  font-weight: normal;
}
.cateringCont p {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Varta', sans-serif;
  font-weight: normal;
  color: #838384;
  word-break: break-word;
  overflow: hidden;
}
.BookingStepFormAccrd .MuiAccordion-root:last-child {
  margin-bottom: 0 !important;
}
.BookingStepFormAccrd .MuiCollapse-container {
  max-height: 40vh;
  overflow-y: auto;
}
.expandIcon img {
  width: 18px;
}

.mrBookingAccordion .MuiCollapse-container {
  max-height: 24vh;
  overflow-y: auto;
}
.mrBookingAccordion .MuiAccordionDetails-root ul.CateringMenuCheckout li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Varta', sans-serif;
  line-height: 20px;
  margin-bottom: 12px;
}
ul.CateringMenuCheckout {
  width: 100%;
}

/* .orderPreviewBtn button:first-child {
  border: 2px solid #000 !important;
  padding: 0 0;
  color: #000 !important;
  opacity: 1 !important;
} */

/* .orderPreviewBtn button:last-child:hover {
  background: transparent !important;
} */
.orderPreviewBtn button {
  margin-bottom: 15px;
}
.orderPreviewBtn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mrBookingAccordion .MuiAccordion-root.Mui-disabled {
  background-color: transparent;
}
.discountBox input {
  color: #444444;
  width: 100%;
  border: solid 0.5px #979797;
  height: 45px;
  padding: 0 15px !important;
  font-size: 16px;
  background: transparent;
  margin-top: 10px;
  font-family: 'Varta', sans-serif;
  line-height: 45px;
  border-radius: 3px;
  text-align: right;
}
.discountBox > div:after,
.discountBox > div:before {
  display: none;
}
.discountBox > div {
  width: 100px;
}
.discountBox small {
  position: absolute;
  top: 25px;
  right: 86px;
}
.discountBox {
  position: relative;
  width: auto;
}
.popupFormButtonLast {
  background: #000000 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
}
.pageBredcrumbTimer .pageContainer.pageBredcrumbFix {
  padding: 18px 30px;
}
.mrBookingAccordion
  .MuiAccordionDetails-root
  ul.CateringMenuCheckout
  li.noOrdered {
  color: #dfdfdf;
}
.cateringCont {
  width: 75%;
  min-height: 85px;
}

.offerDtCat .MuiInputAdornment-positionEnd {
  margin-left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  height: 100% !important;
  max-height: 46px !important;
}
.mrDtCal.offerDtCat .MuiInputAdornment-positionEnd {
  top: 0;
}
.filterDropdownin ul.offerDtCat.mrDtCal input {
  background: rgba(216, 216, 216, 0.19) !important;
  padding: 0 15px !important;
}
.offerDtCat .MuiInputAdornment-root.MuiInputAdornment-positionEnd button {
  padding: 0 12px;
  height: 100%;
}
.offerDtCat .MuiInputAdornment-root.MuiInputAdornment-positionEnd button span {
  height: 46px;
  line-height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.calenderDatePicker .MuiInputAdornment-positionEnd {
  width: 100%;
  margin-left: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100% !important;
  max-height: 46px !important;
}
.calenderDatePicker
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd
  button {
  width: 100%;
  padding: 0 12px;
  height: 100%;
}

li.complimentaryBox p {
  text-transform: capitalize;
  font-size: 16px;
  color: #838384;
  line-height: 22px;
}
.catBooknoResult {
  position: absolute;
}
.meetingTime {
  text-transform: lowercase !important;
}
.BookingStepAll .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
  position: absolute;
}

.filterDropdownin.calenderPicker ul li > span {
  margin-bottom: 12px !important;
  display: block;
}
.filterDropdownin p.Mui-error {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin-top: 5px !important;
}

.BookingStepFormTop li.fullDayBox label {
  margin-right: 0;
  width: 100%;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pageBredcrumbTimer {
  position: sticky;
  top: 70px;
  z-index: 33;
  background: #fff;
}
.BookingStepContainer table tbody.orderPCont tr td:nth-child(2) {
  text-align: right;
  padding-right: 0;
}
.orderTable table .orderPBreakdown tr td:nth-child(2) {
  text-align: right;
}
.BookingStepAll table tbody.orderPCont tr td:nth-child(2) {
  text-align: right;
  padding-right: 0;
}
.BookingStepAll table thead tr td:nth-child(2),
.BookingStepAll table thead tr td:nth-child(3),
.BookingStepAll table thead tr td:nth-child(4),
.BookingStepAll table thead tr th:nth-child(2),
.BookingStepAll table thead tr th:nth-child(3),
.BookingStepAll table thead tr th:nth-child(4),
.BookingStepContainer table thead tr td:nth-child(2),
.BookingStepContainer table thead tr td:nth-child(3),
.BookingStepContainer table thead tr td:nth-child(4),
.BookingStepContainer table thead tr th:nth-child(2),
.BookingStepContainer table thead tr th:nth-child(3),
.BookingStepContainer table thead tr th:nth-child(4) {
  width: 12%;
  text-align: right;
}

button span.MuiButton-label {
  margin-top: 4px;
  width: auto;
}
.rbc-toolbar .rbc-btn-group button.prevArrow img {
  margin: 0 auto;
}
.rbc-toolbar .rbc-btn-group button.prevArrow {
  height: 40px;
  width: 40px;
  border-radius: 100% !important;
  padding: 0;
  text-align: center;
}
.rbc-toolbar .rbc-btn-group button.prevArrow:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

li.fullDayBox > label > span {
  padding-left: 0;
  padding-right: 2px;
}

.booingButton button {
  max-width: 48% !important;
  padding: 0 0px !important;
  margin-left: 0 !important;
  min-width: 48% !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
}
/* .booingButton button.cancelPos {
  width: 100% !important;
  max-width: 100% !important;
} */
.booingButton {
  margin-top: 20px !important;
}
.booingButton {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.booingButton Button:first-child {
  border: 2px solid #000 !important;
}
.MuiFormHelperText-root.Mui-error {
  text-transform: capitalize !important;
}
.tooltipMainBox {
  width: 100px !important;
}

.popupFormButton {
  margin: 0 !important;
}
.meetingRCancelDialog p {
  color: #d60303 !important;
}
.popupFormButton button:first-child {
  border: none !important;
}
.popupFormButton button {
  text-transform: capitalize !important;
}

button.Mui-disabled {
  opacity: 0.1 !important;
}

li.complimentaryBox {
  margin-bottom: 12px !important;
}
li.complimentaryBox > div {
  width: 45px !important;
}
li.complimentaryBox > div > div > div {
  margin-top: 0 !important;
  height: 25px !important;
  line-height: 27px !important;
  text-align: right;
  padding: 0 20px 0 0 !important;
  background-position: right 6px center !important;
}
li.complimentaryBox > div > div > div > input {
  padding: 0 !important;
}
.catteredMenu {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
}

.orderNotes textarea {
  color: #444444;
  width: 100%;
  border: solid 0.5px #979797;
  height: 92px;
  resize: none;
  outline: none;
  padding: 10px 15px !important;
  font-size: 16px;
  background: transparent;
  margin-top: 15px;
  font-family: 'Varta', sans-serif;
  line-height: 22px;
  border-radius: 3px;
  resize: none;
}

.orderNotes.staffNotes button {
  color: #fff !important;
  border: 2px solid #000;
  padding: 0 45px;
  background: #000;
  cursor: pointer;
  height: 47px;
  margin-top: 20px;
  font-family: 'Varta', sans-serif;
  font-weight: bold;
  line-height: 47px;
  font-size: 16px;
  text-transform: capitalize;
}
.orderNotes.staffNotes > span {
  font-size: 15px;
  line-height: 21px;
  color: #444444;
  font-family: 'Varta', sans-serif;
}
.orderNotes.staffNotes > span > div {
  margin-bottom: 10px;
}
.orderDateInvoice {
  text-align: right;
  padding: 16px;
  font-size: 15px;
  line-height: 20px;
  color: #444444;
  font-family: 'Varta', sans-serif;
}
.roomCoctsUnderline a {
  color: #00808f;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
.orderDateInvoice .roomCoctsUnderline {
  margin-top: 5px;
}

.MuiPopover-root {
  z-index: 9999999999999999 !important;
}
.extendPopupFormButton button,
.extendPopupFormButton button:first-child {
  background: #000 !important;
  color: #fff !important;
  margin-top: 20px;
}
.orderMainTable.extendedOrderTable {
  margin-top: 5%;
}
.orderMainTable.extendedOrderTable .cancelShowBtns ul li {
  margin-bottom: 0 !important;
}

.orderPreviewBtn button.cancelBooking {
  /* margin: 0 auto; */
  background: transparent !important;
  border: transparent !important;
  outline: none;
  box-shadow: none;
  color: #000;
}
.orderPreviewBtn button.savedCardBtn {
  /* margin: 0 auto; */
  background: transparent !important;
  border: 2px solid #000 !important;
  box-shadow: none;
  color: #000 !important;
}

/*======saved cards=====*/
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend .MuiDialog-paper h4 {
  font-weight: bold;
  line-height: 20px;
  color: #444444;
  font-size: 19px;
  font-family: 'Varta', sans-serif;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend .MuiDialog-paper ul li p,
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  .MuiDialog-paper
  ul
  li
  small {
  font-size: 15px;
  line-height: 20px;
  color: #444444;
  font-family: 'Varta', sans-serif;
  text-transform: initial;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  .MuiDialog-paper
  ul
  li
  small {
  color: #b1b1b1;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  .MuiDialog-paper
  ul
  li
  img {
  margin-right: 16px;
  width: 60px;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend .MuiDialog-paper ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.allCardList {
  max-height: 50vh;
  overflow: auto;
  margin: 20px 0;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend .MuiDialog-paper ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 36px;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  .MuiDialog-paper
  ul
  li:last-child {
  margin-bottom: 0;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  .MuiDialog-paper
  ul
  li
  button {
  border: none;
  outline: none;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border: 2px solid rgb(216, 216, 216);
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend .MuiDialog-paper {
  padding-top: 40px;
  width: 45%;
}
.MuiDialog-root.cancelDialogOpend.savedCardPopupOpend
  button
  span.MuiButton-label {
  width: 100%;
}
.cardActive button {
  border-color: #000 !important;
}
button.popupCloseBtn img {
  width: 22px;
}
button.popupCloseBtn {
  position: absolute;
  right: 20px;
  top: 15px;
  background: transparent;
  padding: 7px;
  width: auto;
  min-width: auto;
  border-radius: 100%;
}
button.popupCloseBtn span {
  padding: 0;
  margin: 0;
}
button.popupCloseBtn img {
  width: 22px;
}

.mrBookingStepsWithoutCatering .mrBookingStepsLabel .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-1.svg) no-repeat;
  top: -6px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 15px;
}
.mrBookingStepsWithoutCatering:nth-child(2)
  .mrBookingStepsLabel
  .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-3.svg) no-repeat;
  top: -6px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 22px;
}
.mrBookingStepsWithoutCatering:nth-child(3)
  .mrBookingStepsLabel
  .MuiStepLabel-iconContainer:after {
  position: relative;
  content: '';
  background: url(../../images/stepper-icon-4.svg) no-repeat;
  top: -3px;
  left: 0;
  background-size: contain;
  height: 28px;
  width: 22px;
}
.cancelDialogWidth .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
width: 50%;
}
.orderPBreakdown .orderDiscount .MuiInputBase-root.MuiInput-root.MuiInput-underline{
width: 64px ;
}