.sideBar .MuiListItem-root.Mui-selected,
.sideBar .MuiListItem-root.Mui-selected:hover {
  background: #000 !important;
}
.sideBar .MuiListItem-root.Mui-selected span,
.sideBar .MuiListItem-root.Mui-selected:hover span {
  color: #fff !important;
}
.sideBar {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}
.sideBar li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
