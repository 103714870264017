.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}

.addUserContainer .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.PrivateRadioButtonIcon .MuiSvgIcon-root {
  font-size: 16px;
}
.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}
.cancelDialogOpend {
  z-index: 999999 !important;
}
.loaderZindex{
  z-index: 999999999 !important;
  color: white;
}

.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.addMeetingRoomUserContainer li h3 {
  font-size: 16px;
  font-weight: bold;
  color: #838384;
  font-family: 'Varta', sans-serif;
  line-height: 20px;
}
.pageContainer.addMeetingRoomUserContainer {
  margin-top: 0;
  position: relative;
}
.textareaSection li {
  width: 100% !important;
  position: relative;
}
.descriptionBox {
  position: absolute;
  right: 10px;
  top: 100px;
  text-align: right;
  font-size: 10px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  background: #fff;
  width: auto !important;
}

ul.MultipleImgUpload li {
  width: 12% !important;
  position: relative;
}
ul.MultipleImgUpload li input[type='file'] {
  opacity: 0 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: bottom;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  padding: 0;
}
ul.MultipleImgUpload li div:after,
ul.MultipleImgUpload li div:before {
  display: none;
}
ul.MultipleImgUpload > li > div {
  position: relative;
  left: 0;
  top: 0;
  height: 85px;
  width: 100%;
  border: solid 1px rgba(151, 151, 151, 0.15);
  z-index: 1;
  cursor: pointer;
}

.imageAddIcon {
  position: absolute;
  top: 29px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 85px;
  width: 100%;
  line-height: 85px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.imageAddIcon img {
  margin: 0 auto;
}
.imageAddName {
  font-size: 14px;
  font-weight: bold;
  color: #838384;
  font-family: 'Varta', sans-serif;
  text-align: center;
  margin: 0 0 10px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 14px;
  line-height: 18px;
}
.popImgUpload {
  font-size: 16px;
  font-weight: normal;
}

.removeImage {
  position: absolute;
  right: -10px;
  top: 15px;
  cursor: pointer;
  z-index: 99;
  width: 20px;
}
li.singleDescpt {
  width: 100% !important;
}
li.singleDescpt > ul > li {
  width: 100% !important;
}

.breadcrumbs ol li svg {
  font-size: 17px !important;
}
.vatText {
  font-family: 'Varta', sans-serif;
  font-size: 11px;
  line-height: 15px;
  color: #838384;
  position: absolute;
  right: 6px;
  top: 43px;
  width: auto !important;
}
.InputSectionMeeting li {
  position: relative;
}
ul.MultipleImgUpload p.MuiFormHelperText-root {
  text-transform: capitalize;
}

.YesNoBtn {
  color: #838384;
  font-size: 16px !important;
  transform: none;
  font-family: 'Varta', sans-serif !important;
  font-weight: normal !important;
}
.YesNoSwitchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#StatusInAddMeetingRoom {
  margin-bottom: -30px;
}
#showOnMobileButtonArea {
  padding-top: 30px;
  padding-right: 100px;
}
#showOnMobileButtonName {
  padding-top: 13px;
}
#showMobButtonInView {
  padding-top: 30px;
  padding-right: 100px;
}
#showOnMobBtnName {
  padding-top: 13px;
}

th.MuiTableCell-root.MuiTableCell-head.tableHead {
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  color: #838384;
}
tr.MuiTableRow-root.MuiTableRow-head.tableHeader {
  border-bottom: 2px solid #979797 !important;
}
.saveButtonForLayout {
  color: #fff !important;
  width: auto;
  border: 1px solid #000 !important;
  cursor: pointer !important;
  height: 47px !important;
  outline: none !important;
  padding: 0 45px !important;
  font-size: 16px !important;
  background: #000000 !important;
  font-family: Varta, sans-serif !important;
  font-weight: bold !important;
  line-height: normal !important;
}
.cancelBtnLayout {
  color: #000000 !important;
  padding: 0 32px !important;
  background: transparent !important;
}
.layoutBrd {
  border: solid 1px rgba(151, 151, 151, 0.15);
  max-width: 70px;
  max-height: 56px;
  text-align: center;
  height: 85px;
  width: 100%;
  line-height: 85px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.addLayoutBtn {
  display: flex;
  justify-content: flex-start;
  padding: inherit;
  position: relative;
  padding-left: 16px;
}

.layoutBrd img {
  margin: 0 auto;
}
.d_none {
  display: noe;
}
.d_flex {
  display: flex;
}
.tblRowData td {
  color: rgb(161, 161, 161) !important;
}
.lastRow td {
  border-bottom: none;
}
.layoutBoxStyle {
  border: solid 0.5px #979797;
  width: 100%;
  height: fit-content;
  padding: 20px;
  color: #444444;
  border-radius: 3px;
  margin-top: -15px;
}
