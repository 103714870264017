.BuildingDetails li {
  width: 66% !important;
}

.BuildingDetails li:first-child {
  width: 100% !important;
}

.BuildingLocations {
  display: block !important;
}

.BuildingDesc {
  padding-top: 15px;
}

.BuildingVilgLocations {
  padding-bottom: 15px;
}

.BuildingDesc li:first-child {
  width: 100% !important;
}

.AgentDetails li:last-child {
  width: 66% !important;
}

ul.MultipleImgUpload li {
  margin-bottom: 32px !important;
}

.imageAddIconUpload img[disabled] {
  pointer-events: none;
}

.phoneSelect {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.phoneSelectCode {
  width: 22%;
}

.phoneSelectNo {
  width: 72%;
}

.phoneSelectNo > div:before,
.phoneSelectNo > div:after {
  display: none;
}

.phoneSelectCode div,
.phoneSelectNo div {
  width: 100%;
}

.phoneSelectCode > div > div {
  padding: 0 20px 0 10px !important;
  background-position: 83% 47% !important;
}

.BuildingDesc li:last-child input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agentName {
  text-transform: capitalize;
}

ul.offerFormList li {
  margin-bottom: 32px !important;
}

.formDivider.popupDivider {
  margin: 30px auto !important;
}

.tableContainer table tbody tr td,
.tableContainer table tbody tr th {
  height: 61px;
}

/* .cardPrevWithLogo.viewLoyaltyCardPrev{
  pointer-events: none;
  opacity: 0.6 !important;
    cursor: not-allowed;
}*/

.cardPrevWithLogo {
  border-radius: 15px;
  padding: 14px;
  max-width: 369px;
}

.cardNameAndPic ul {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.cardNameAndPic ul li {
  margin: 0 !important;
  width: 30% !important;
}

.cardNameAndPic ul li:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cardNameAndPic ul li:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.prevCardDesc {
  margin: 20px 0;
}

.prevCardDesc p {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  max-height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.cardDateAndLogo ul li img {
  margin: 0 auto;
  max-height: 36px;
}

.cardPrevForBuilding .prevCardDesc span {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Conv_AtlasGroteskApp-Light';
}

.cardDateAndLogo ol li {
  width: 100% !important;
  background: #ffffff;
  border-radius: 6px;
  padding: 0px 3px;
  height: 54px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.cardDateAndLogo ol li:last-child {
  margin-right: 0;
}

.cardDateAndLogo ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cardNameAndPic img {
  max-height: 50px;
  border-radius: 50px;
}

.cardPrevForBuilding {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cardPrevForBuilding > ul {
  width: 50%;
}

.cardPrevForBuilding > ul > li {
  width: 100% !important;
}

.cardPrevForBuilding .cardPrevWithLogo {
  background: url(../../images/CardBgImage.png);
  background-size: cover;
  color: #fafaf8;
  max-width: 338px;
  padding: 16px 18px 12px 24px;
}

.cardPrevForBuilding .cardDateAndLogo ul li {
  width: 45%;
  margin: 0;
}

.cardPrevForBuilding .prevCardDesc {
  margin: 0px 0 20px;
}

.cardPrevForBuilding .prevCardDesc h3 {
  font-family: 'Conv_AtlasGrotesk-Bold-App';
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 3px;
}

.cardPrevForBuilding .cardDateAndLogo ul {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cardPrevForBuilding .cardNameAndPic img {
  max-height: 70px;
  border-radius: 100%;
  max-width: 70px;
}

.cardPrevForBuilding .cardDateAndLogo ul li:last-child {
  width: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cardPrevForBuilding .cardDateAndLogo ul li:last-child img {
  margin: 0;
}

.cardPrevForBuilding .cardNameAndPic h1 {
  margin-top: 8px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  font-family: 'Conv_ArtCompanyMono-Light';
  word-spacing: -8px;
}

.cardPrevForBuilding .cardNameAndPic ul li:first-child {
  width: 60% !important;
}

ul.buildingAttributeImages.MultipleImgUpload {
  /* margin-top: -78px; */
}

.cardDateAndLogo li span {
  font-family: 'Conv_JohnstonITCPro-Light';
  display: block;
  font-size: 12px;
  line-height: 16px;
}

.imageAddName {
  width: 300px;
}

.advisoryNoteCaption {
  font-size: 10px;
  width: 205px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  text-align: left;
  margin: 0 0 0px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 14px;
  line-height: 18px;
  width: 300x;
}
.setTiming {
  border: solid 0.5px #979797;
  outline: 0;
}
.textAlign_center {
  text-align: center;
}
.alignItems_center {
  align-items: center;
}
.mt_n10 {
  margin-top: -10px !important;
}
.mt_10 {
  margin-top: 10px !important;
}
.mb_10 {
  margin-bottom: 10px;
}
.m_0 {
  margin: 0px;
}
.d_inherit {
  display: inherit;
}
.top_n11 {
  top: -11px;
}
.top_11 {
  top: 11px;
}
.top_35 {
  top: 35px;
}
.top_47 {
  top: 47px;
}
.imageAddIconThumbnail {
  top: 43px;
}
.advisory_count_check {
  position: absolute;
  right: 12px;
  bottom: 10px;
  text-align: right;
  font-size: 10px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  background: #fff;
  width: auto !important;
}
.position_relative {
  position: relative;
}
@media (max-width: 991px) {
  .cardPrevForBuilding {
    display: block;
    margin: 0 -21%;
  }

  .cardPrevForBuilding > ul {
    width: 100%;
  }

  ul.buildingAttributeImages.MultipleImgUpload {
    margin-top: 0;
  }
}
#mobileAccessBlocked {
  color: #FF0000;
}
.notFound{
  color: #838384;
  font-size: 16px;
  transform: none;
  font-family: Varta, sans-serif;
  font-weight: normal;
}

.noTenantResult h2,
.noTenantResult p {
  font-size: 20px;
  font-weight: bold;
  color: rgba(131, 131, 132, 0.43);
  margin-bottom: 10px;
  font-family: 'Varta', sans-serif;
}
.noTenantResult h2 {
  text-transform: uppercase;
}
.tenantNotFound {
  height: 80px;
}
.noTenantResult {
  text-align: center;
  position: relative;
  top: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}