.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}
.addUserContainer .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.PrivateRadioButtonIcon .MuiSvgIcon-root {
  font-size: 16px;
}
.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}

.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.addMeetingRoomUserContainer li h3 {
  font-size: 16px;
  font-weight: bold;
  color: #838384;
  font-family: 'Varta', sans-serif;
  line-height: 20px;
}
.pageContainer.addMeetingRoomUserContainer {
  margin-top: 0;
}
.textareaSection li {
  width: 100% !important;
  position: relative;
}
.offerFormSortDesc.descriptionBox {
  top: 50px;
  bottom: auto;
}

ul.MultipleImgUpload li {
  width: 12% !important;
  position: relative;
  min-width: 100px;
}
.mouseClickCount img {
  width: 16px;
}
.offerImgUoload ul.MultipleImgUpload li:first-child {
  width: 12% !important;
}
ul.MultipleImgUpload li div:after,
ul.MultipleImgUpload li div:before {
  display: none;
}
ul.MultipleImgUpload > li > div {
  position: relative;
  left: 0;
  top: 0;
  height: 85px;
  width: 100%;
  border: solid 1px rgba(151, 151, 151, 0.15);
  z-index: 1;
}
.offerImgUoload .uploadsize {
  font-size: 10px;
  font-weight: normal;
  color: #838384;
  font-family: 'Varta', sans-serif;
  text-align: center;
  margin: 5px 0 0;
  display: block;
}
.offerImgUoload ul.MultipleImgUpload li:first-child span {
  display: flex;
}
.breadcrumbs {
  margin-bottom: -5px;
}

ul.offerFormList li:first-child legend {
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #838384;
}
ul.offerFormList li {
  width: 100% !important;
}
ul.offerDtCat li {
  width: 23% !important;
}
ul.digitalType li {
  width: 32% !important;
  margin-right: 33px;
}
ul.digitalType {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.offerForm ul li div.MuiInput-formControl:after,
.offerForm ul li div.MuiInput-formControl:before {
  display: none !important;
}
.offerForm ul li div.MuiInput-formControl {
  margin-top: 0 !important;
}
ul.physicalTypeSub {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
ul.physicalType > li {
  width: 100% !important;
}
.physicalTypeSub li,
.physicalTypeSubWrap li {
  width: 31% !important;
}
.physicalTypeSub li:first-child,
.physicalTypeSub li:nth-child(2) {
  width: 65% !important;
}
.physicalTypeSubWrap {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.physicalTypeSubWrap li {
  margin-right: 33px;
}
ul.richTextEdit li {
  width: 100% !important;
}
ul.richTextEdit li:last-child input {
  height: 85px;
}
ul.locationFilterDropdown > li > li {
  width: 100%;
}
ul.locationFilterDropdown > li {
  width: auto !important;
}
ul.offerImgUoload > li {
  width: 100% !important;
}
ul.offerImgUoload li ul.MultipleImgUpload {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.offerImgUoload ul.MultipleImgUpload li:first-child {
  margin-right: 40px;
}
.MuiPickersToolbar-toolbar {
  background: #000 !important;
}
.MuiDialogActions-root .MuiButton-textPrimary {
  color: #000 !important;
}
.MuiPickersClock-pin {
  background: #000 !important;
}
.MuiPickersClockPointer-pointer {
  background: #000 !important;
}
.MuiPickersClockPointer-noPoint {
  background: #000 !important;
}
.MuiPickersClockPointer-thumb {
  border: 14px solid #000 !important;
}
.MuiPickersDay-daySelected {
  background: #000 !important;
}

.MuiPickersDay-day {
  color: rgba(117, 113, 113, 0.8) !important;
}
.MuiPickersDay-current {
  color: #000 !important;
}
.MuiPickersDay-daySelected {
  color: #fff !important;
}
.MuiPickersYearSelection-container .MuiTypography-colorPrimary {
  color: #000 !important;
}
ul.offerlocationFilterDropdown button span {
  text-transform: initial !important;
}

ul li textarea.textareaSmallBox {
  height: auto !important;
  padding: 6px 43px 6px 15px !important;
  line-height: 18px;
  max-height: 57px;
}

.offerFormSortDesc.descriptionBox.offerFormText {
  top: 60px;
  bottom: auto;
}
.ql-editor {
  font-size: 16px;
  font-family: 'Varta', sans-serif;
  line-height: 22px !important;
  color: #444444 !important;
}
.quill {
  margin-top: 10px;
}

li div.textareaSmallBox input {
  padding: 0 45px 0 15px !important;
}

.pageContainer ul li .buildSelectedBtn span {
  text-transform: initial !important;
}

ul.offerlocationFilterDropdown.offerVillageFilterDropdown {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
ul.offerlocationFilterDropdown.offerVillageFilterDropdown li {
  width: 46% !important;
}
ul.offerlocationFilterDropdown.offerVillageFilterDropdown li li {
  width: auto !important;
  display: inline-block !important;
}

.locationFilterLabel.MuiFormLabel-root{
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #838384;
  margin-bottom: 32px;
  display: block;
}
.mb-19{
  margin-bottom: 16px !important;
}