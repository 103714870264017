.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}

.pageContainer.addUserContainer {
  margin-top: 0;
  position: relative;
}
.pageContainer.pageBredcrumbFix {
  position: sticky;
  top: 76px;
  border-bottom: 1px solid rgb(223 223 223 / 38%);
  background: #fff;
  z-index: 222222;
}
.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}

.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}
.ditIcon {
  position: absolute;
  right: 4%;
  top: 38px;
  cursor: pointer;
}

.comboBoxSerch > div > div {
  color: #444444;
  border: solid 0.5px #979797;
  height: 45px;
  padding: 0 0 !important;
  font-size: 16px;
  background: transparent url(../../images/down-arw.svg) no-repeat 95% center !important;
  margin-top: 16px;
  font-family: 'Varta', sans-serif;
  line-height: 45px;
  border-radius: 3px;
  background-size: 10px;
  -webkit-appearance: none;
  display: block;
  outline: none;
  box-sizing: content-box;
}
.comboBoxSerch > div > div input {
  width: 100% !important;
  height: 100%;
  padding: 0 15px !important;
  box-sizing: border-box;
  cursor: pointer;
}
.comboBoxSerch > div > div:after,
.comboBoxSerch > div > div:before,
.comboBoxSerch
  > div
  > div
  > div.MuiAutocomplete-endAdornment
  button:nth-child(2) {
  display: none;
}
.comboBoxSerch
  > div
  > div
  > div.MuiAutocomplete-endAdornment
  button:nth-child(1) {
  padding: 6px;
  margin-right: 10px;
  background: #fff !important;
  top: -2px;
  z-index: 2;
  right: 0;
  position: absolute;
}
.MuiDivider-light {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.clearField {
  position: absolute;
  right: 12px;
  top: 37px;
  border: none;
  outline: none;
  padding: 0;
  background: #fff;
  cursor: pointer;
}
button.clearField svg {
  font-size: 20px;
}
.customMail {
  position: relative;
}
.inputSectionAlnBtm label + .MuiInput-formControl {
  margin-top: 10px;
}
.inputSectionAlnBtm .customMail input {
  margin-top: 0;
}
.inputSectionAlnBtm label + .MuiInput-formControl {
  margin-top: 10px;
}
.customMail > div > div:before,
.customMail > div > div:after {
  display: none !important;
}

.MuiAutocomplete-listbox {
  background-color: #fafafa !important;
}
.MuiAutocomplete-option {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}
.MuiAutocomplete-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
}

li.customMail button {
  position: absolute;
  right: 12px;
  top: 27px;
  border: none;
  outline: none;
  padding: 0;
  background: #fff;
  cursor: pointer;
  min-width: auto;
  height: 45px;
}
li.customMail button > span {
  height: 28px;
}
label.MuiFormLabel-root.statusCss {
  color: #838384;
  font-family: Varta, sans-serif;
  font-weight: normal;
  font-size: 16px;
}
.MuiFormGroup-root {
  margin-top: 20px;
}