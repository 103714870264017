.failedPaymentBody {
    min-height: 100vh;
    background: url(../../images/login-banner.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    /* width: 100%; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.failedPaymentCardBox {
    z-index: 1;
    position: relative;
    background: #fff;
    padding: 50px 30px;
    width: 65%;
    min-width: 600px;
    border-radius: 6px;
}

.failedPaymentBody:after {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
}

.failedPaymentBody button {
    color: #fff;
    width: 180px;
    /* border: 2px solid #00808f; */
    cursor: pointer;
    height: 47px;
    outline: none;
    padding: 0 0;
    font-size: 16px;
    background: #00808f !important;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
    line-height: 47px;
    margin: 0 auto;
    display: block;
}

.sq-card-wrapper.sq-focus .sq-card-iframe-container::before {
    border: inset 2px #000000 !important;
}

.sq-card-wrapper .sq-card-message-no-error {
    font-size: 14px !important;
    color: #2A2A2A !important;
    font-family: 'Conv_AtlasGrotesk-Regular-App' !important;
}

.sq-card-wrapper .sq-card-message {
    font-family: 'Conv_AtlasGrotesk-Regular-App' !important;
    margin: 15px 0 !important;
}

.failedPaymentLogo {
    width: 150px;
    margin: 0 0 40px;
}

.form#form,
.failedPaymentBody>*,
form#form>* {
    font-family: 'Conv_AtlasGrotesk-Regular-App' !important;
}

.cardForMtName {
    font-size: 16px;
    line-height: 21px;
    color: #444444;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}

.cardForMtName.cardForMtPrice {
    margin-top: 5px;
    font-weight: bold;
}

.cardForMtName.cardForMtPrice span {
    margin-left: 10px;
}

.cardForMtDetails {
    margin-bottom: 25px;
}

.FailedPTable {
    margin-bottom: 30px;
}

.orderPCont.failedTPro tr td {
    color: #444444 !important;
}

.failedPaymentCardBox h6 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 25px;
    color: #2A2A2A;
    font-family: 'Conv_AtlasGrotesk-Bold-App';
}

.failedPaymentBody .cancelPCButton button {
    position: absolute;
    right: 0;
    background: transparent !important;
    border: none !important;
    color: #00808f !important;
    border-radius: 5px;
    width: 140px;
    /* display: none; */
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}

.cancelPCButton {
    position: relative;
    min-height: 50px;
    display: block;
    width: calc(100% - 200px);
}

.failedPaymentBody button#pay-with-card {
    position: absolute;
    right: 0;
    left: auto;
}

.cardonlyPayment {
    position: relative;
}

.gpay-card-info-container-fill,
.gpay-card-info-container-fill>.gpay-card-info-container {
    height: 47px !important;
    min-width: 100% !important;
}

div#apple-pay-button,
div#google-pay-button {
    height: 50px !important;
}

.gPayApayBlock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.gPayApayBlock>div {
    width: 48% !important;
}


.failedOrderSumary ul {
    margin-bottom: 25px;
}

.failedOrderSumary ul:last-child {
    border-top: 2px solid #2A2A2A;
    border-bottom: 2px solid #2A2A2A;
    padding: 20px 0;
}

.failedOrderSumary ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: #2A2A2A;
    margin-bottom: 5px;
}

.failedOrderSumary ul li:first-child {
    font-family: 'Conv_AtlasGrotesk-Bold-App';
}

.failedOrderSumary ul:last-child li {
    margin: 0;
}

.failedOrderSumary ul li span {
    max-width: 80%;
}

.failedPaymentBody.cardBlur {
    filter: blur(2px);
    pointer-events: none;
}

.failedPaymentCardBox .noResult {
    position: static;
    transform: none;
}

.failedPaymentCardBox .noResult h2,
.failedPaymentCardBox .noResult p {
    font-weight: normal;
    font-family: 'Conv_AtlasGrotesk-Bold-App';
    font-size: 17px;
    line-height: 22px;

}

.failedPaymentCardBox .noResult p {
    font-family: 'Conv_AtlasGrotesk-Regular-App';

}

.failedPaymentCardBox .noResult h2 {
    color: #2A2A2A;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}

.failedPaymentCardBox .noResult.cardPaymentSuccess p {
    color: #2A2A2A;
    font-family: 'Conv_AtlasGrotesk-Bold-App';
}

.failedPaymentCardBox .noResult.cardPaymentError p {
    color: #2A2A2A;
    font-family: 'Conv_AtlasGrotesk-Bold-App';
}

.cancelPCButton button {
    display: none;
}

.billingAddress ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.billingAddress ul li {
    width: 100%;
    margin-bottom: 15px;
}

.billingAddress ul li>div {
    width: 100%;
    background: transparent;
}

.billingAddress ul li.formSelectDropdown>div>div svg {
    display: none;
}

.billingAddress ul li>div>div {
    width: 100%;
    background: rgba(218, 218, 218, 1) !important;
    border: solid 2px #00808F;
    border-radius: 4px;
    font-size: 16px;
    color: rgba(109, 107, 107, 1);
    font-family: 'Conv_AtlasGrotesk-Regular-App';

}

.billingAddress ul li.formSelectDropdown>div>div {
    background: rgba(218, 218, 218, 1) url(/src/images/down-arw-blue.svg) no-repeat 95% center !important;
    background-size: 25px !important;
}

.billingAddress ul li>div label {
    color: rgba(109, 107, 107, 1) !important;
}

.billingAddress ul li>div>div input {
    font-size: 16px;
    color: rgba(109, 107, 107, 1);
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}

.billingAddress ul li .MuiFilledInput-root:hover {
    background: rgba(218, 218, 218, 1) !important;
}

.billingAddress ul li.formSelectDropdown .MuiFilledInput-root:hover {
    background: rgba(218, 218, 218, 1) url(/src/images/down-arw-blue.svg) no-repeat 95% center !important;
    background-size: 25px !important;
}

.billingAddress ul li>div>div:before,
.billingAddress ul li>div>div:after {
    display: none;
}



.billingAddress ul li:nth-child(4),
.billingAddress ul li:nth-child(5) {
    width: 48%;
}

.billingAddress {
    margin-bottom: 30px;
}

.billingAddress ul li input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(109, 107, 107, 1) !important;
    opacity: 1;
}

.billingAddress ul li input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(109, 107, 107, 1) !important;
    opacity: 1;
}

.billingAddress ul li input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(109, 107, 107, 1) !important;
    opacity: 1;
}

.billingAddress ul li input:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(109, 107, 107, 1) !important;
    opacity: 1;

}

.sq-card-wrapper.sq-focus .sq-card-iframe-container {
    border-color: transparent !important;
}

.sq-card-wrapper .sq-card-iframe-container {
    border: solid 2px #00808F !important;
}

.sq-card-wrapper.sq-focus .sq-card-iframe-container::before {
    border: solid 2px #00808F !important;
}

.sq-card-wrapper.sq-error .sq-card-iframe-container::before {
    border: solid 2px #cc0023 !important;
}

iframe#iframe-paymentContainer {
    height: 300px;
}

@media only screen and (max-width: 767px) {
    .failedPaymentCardBox {
        min-width: 100%;
    }

    .failedPaymentBody .cancelPCButton button {
        left: 0;
        margin: 0;
        width: 110px;
    }

    .failedPaymentBody button {
        width: 200px;
    }

    .cancelPCButton {
        display: inline-block;
        width: auto;
    }

    div#card-container {
        margin: 40px 0 0px !important;
    }

    .failedPaymentCardBox {
        padding: 25px 20px;
        width: 100%;
    }

    .failedPaymentCardBox {
        border-radius: 0;
    }


}