.pageContainer.notificationContainer {
    padding-left: 40px;
}
.notificationWrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.notificationFullW li{
    width: 65% !important;
}

ul.notificationDtCat li {
    width: 40% !important;
}
.notificationDtCat .MuiInputBase-root.MuiInput-root.MuiInput-underline:before,
.notificationDtCat .MuiInputBase-root.MuiInput-root.MuiInput-underline:after{
    display: none;
}

.MuiPickerDTTabs-tabs {
    color: #000 !important;
    background-color: #fff !important;
}
.MuiPickerDTTabs-tabs span.MuiTabs-indicator{
    background-color: #000;
}
.notificationCardBlock .selectedOfferBlock ul.offerCardBoxAdded {
    display: flex !important;
    justify-content: flex-start !important;
    max-height: 310px;
    overflow-y: auto;
}
.notificationCardBlock .selectedOfferBlock ul li {
    width: 24% !important;
    margin-right: 1.3%;
}
.notificationCardBlock .selectedOfferBlock ul li:nth-child(4n) {
    margin-right: 0;
}
ul.dietrySelectBox.notificationSelectBox{
    justify-content: flex-start !important;
}
.notificationCardBlock ul.dietrySelectBox.notificationSelectBox{
    justify-content: space-between !important;
}
ul.dietrySelectBox.notificationSelectBox > li{
    width: 46% !important;
}
.notificationCardBlock .selectedOfferBlock ul li .offerCardBox {
    min-height: 250px;
}
.notificationCardBlock .offerLogoBlock {
    min-height: 180px;
    max-height: 180px;
}
.notificationSvBtn{
    margin-top: 40px;
}
.selectedOfferBlock {
    border-top: solid 2px #979797;
    padding-top: 20px;
}
.notificationCardBlock .exportSerch ul, 
.notificationCardBlock .exportSerch ul li {
    width: auto;
    margin: 0;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.notificationCardBlock ul.dietrySelectBox.notificationSelectBox {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
}
.notificationForm h2{
    font-family: 'Varta', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #838384;
    margin-bottom: 32px;
}
.scdlNtfBlock > ul > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 4px solid #F1F1F1;
    padding: 20px 0 20px;
}
.scdlNtfBlock {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    /* min-height: 100vh; */
}
.notificationWrap > .CreateNotificationForm {
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    /* min-height: 100vh; */
  }
.sdlNtfFetchData{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.scdlNtfBlock > ul{
    position: relative;
    
}

.scdlNtfBlock > ul:first-child > li:first-child{
    padding: 0 0 20px;
}
.notificationCardBox {
    width: 40%;
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    min-height: 231px;
    padding: 10px;
}
.scdlNtfBlock .offerLogoBlock {
    min-height: 170px;
    max-height: 170px;
}
.sdlNtfDetails {
    width: 60%;
    padding-left: 16px;
}
.sdlNtfDetailsHead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.sdlNtfLogo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.sdlNtfLogo span {
    font-size: 13px;
    line-height: 17px;
    color: #3F3F3F;
    font-family: 'Varta', sans-serif;
    display: block;
    margin-left: 6px;
}
.sdlNtfDate{
    font-size: 13px;
    line-height: 17px;
    color: #3F3F3F;
    font-family: 'Varta', sans-serif;
    display: block;
}
.sdlNtfDetails h3 {
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
    font-family: 'Varta', sans-serif;
    font-weight: bold;
    color: #000;
}
.sdlNtfDetails p {
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
    font-family: 'Varta', sans-serif;
    color: #000;
    max-height: 82px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sdlNtfDetails ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 15px;
}
.sdlNtfDetails ol li {
    padding: 4px;
    font-size: 13px;
    -webkit-box-shadow: none;
            box-shadow: none;
    font-family: 'Varta', sans-serif;
    font-weight: bold;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    width: 32%;
    text-align: center;
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.cancelSdlNtf {
    text-align: right;
    margin-top: 20px;
}
.cancelSdlNtf button {
    background: transparent !important;
    color: #FF0000;
    font-weight: bold;
    font-size: 14px;
    font-family: 'Varta', sans-serif;
}
.notificationCardBlock .publishDateMain .publishDate{    
    width: 50%;
}
.notificationSelectBox li ul li{
    position: relative !important;
    width: auto !important;
}
ul.dietrySelectBox.notificationSelectBox li > span{    
    margin-bottom: 16px;
}
.notificationCardBlock .selectedOfferBlock ul li.active .offerCardBox {
    border-color: #000;
}
.offerLocationBtn.ntfLocationBtndisb {
    margin-top: 16px;
}

.sdlNtfDetailsGreyBox{
    background: #F5F5F5;
    border-radius: 13px;
}
.sdlNtfDetailsGreyBox {
    background: #F5F5F5;
    border-radius: 13px;
    padding: 12px;
}
.notificationDisable {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}
ul.notificationSelectBox p.buildingErrorMsg {
    margin-top: -10px;
}