.pageContainer.tableContainer,
.pageContainer.filterContainer {
  margin-top: 0;
}
.pageContainer.filterContainer {
  padding: 8px 30px;
}

.breadcrumbs ol li,
.breadcrumbs ol li:hover,
.breadcrumbs ol li:focus {
  font-size: 14px;
  color: #838384;
  font-family: 'Varta', sans-serif;
  text-transform: capitalize;
}
.breadcrumbs ol li a,
.breadcrumbs ol li a :hover,
.breadcrumbs ol li a :focus {
  text-decoration: none;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.filterDropdownin {
  padding: 20px 15px;
}
.filterDropdownin input {
  border-radius: 3px;
  background-color: rgba(216, 216, 216, 0.19);
  background-image: url(../../images/search.svg);
  background-repeat: no-repeat;
  background-position: center left 15px;
  background-size: 15px;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  padding-left: 40px;
  width: 100%;
  display: block;
  font-size: 14px;
  font-family: 'Varta', sans-serif;
  color: #444444;
}
.filterDropdownin .MuiInputBase-root:before,
.filterDropdownin .MuiInputBase-root:after {
  display: none;
}
.filterDropdownin .MuiInputBase-root {
  width: 100%;
}
.filterDropdown {
  width: 300px;
}
.serchFilter .MuiInputBase-root:before {
  display: none;
}
.filterDropdownin.filterDropdownSch {
  max-height: 400px;
  padding-top: 0;
}
.filterDropdownSchBox {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  margin-bottom: 0;
  padding-top: 20px;
}
.filterDropdownin p {
  font-size: 12px;
  font-weight: normal;
  margin-top: 22px;
  font-family: 'Varta', sans-serif;
  color: #838384;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px 5px;
}
.filtercheckTop ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.filtercheckTop ul li {
  width: 15.8%;
  border-bottom: 1px solid rgba(151, 151, 151, 0.15);
  margin-right: 0.9%;
  min-width: 190px;
}
.filtercheckTop ul li:nth-child(6n) {
  margin-right: 0;
}
.filtercheckTop > ul > li > label {
  width: 100%;
}
.filterDropdown.filterDropdownCheck {
  width: 100%;
  max-height: 290px;
}
.filtercheckTop ul {
  padding: 0 0 10px;
}
.exportSerch li .serchFilter {
  position: relative;
  opacity: 0;
  width: 0;
  visibility: hidden;
  height: 0;
  transition: all ease-in-out 0.5s;
  display: none;
}
.exportSerch li.searchExpanded .serchFilter {
  opacity: 1;
  width: 300px;
  height: auto;
  visibility: visible;
  transition: all ease-in-out 0.5s;
  display: block;
}
.exportSerch li.searchExpanded img {
  display: none;
}
.exportSerch li img {
  cursor: pointer;
  width: 20px;
}
.exportSerch li .serchFilter input,
.exportSerch li .serchFilter > div {
  width: 100%;
}
.rightFilterPagination > div > div > p {
  color: #838384;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
  left: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
.filterDropdownin p:hover {
  background: rgba(210, 210, 210, 0.2);
  cursor: pointer;
}
.filterDropdownin.filterDropdownSch span {
  font-size: 11px;
  font-weight: bold;
  margin-top: 22px;
  font-family: 'Varta', sans-serif;
  color: #838384;
  margin-left: 12px;
  display: block;
}
i.closeIconBtn {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}
.eventNameUnderline {
  text-decoration: underline;
  cursor: pointer;
}
.filterDropdown.filterDropdownCheck.offerFilterDropdownCheck
  .MuiListItem-gutters {
  padding-left: 0;
}

.filterDropdown.filterDropdownCheck {
  width: 100%;
  max-height: 290px;
  min-width: 150px;
  min-height: 100px;
}
.smallLoader {
  text-align: center;
}
span.suplHeading {
  margin-bottom: 20px !important;
}

ul.offerFormList li.statusMarBtm {
  margin-bottom: 20px !important;
}

.filterDropdown .MuiCheckbox-colorSecondary.Mui-checked svg,
.filterDropdown .MuiCheckbox-colorSecondary.Mui-checked {
  fill: #000000;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background: transparent !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.tableContainer .active:after {
  transform: translateY(-50%) rotate(180deg) !important;
  transition: all ease-in-out 0.5s;
}
.tableContainer th:after {
  transition: all ease-in-out 0.5s;
}

ul.eventDtBox li {
  width: 31% !important;
}

ul.eventDtBox li .eventLocationF > li {
  width: 100% !important;
  margin: 10px 0 0 !important;
}
.eventLocationF > li button {
  height: 45px !important;
  line-height: 45px !important;
  padding: 0 12px !important;
  width: 100% !important;
}
.eventLocationF > li button > span.MuiButton-label {
  width: 100% !important;
  justify-content: flex-start !important;
}
.eventLocationF > li button > span.MuiButton-label small {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.richTextEditViewDisb textarea[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.eventGuest {
  text-transform: lowercase !important;
}

.exportSerch li {
  margin-left: 8px !important;
  padding: 13px;
  border-radius: 100%;
  cursor: pointer;
}
.exportSerch li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.exportSerch li.searchExpanded:hover {
  background-color: transparent;
}
.exportSerch li.searchExpanded {
  padding: 0;
}
.calenderPicker ul.offerDtCat li {
  width: 100% !important;
}
.filterDropdownDtRange {
  width: 100%;
}
.filterDropdownDtRange ul.offerDtCat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.filterDropdownDtRange .calenderPicker ul.offerDtCat li {
  width: 47% !important;
}
.pageContainer ul li label,
.pageContainer ul li span {
  text-transform: capitalize !important;
}
.pageContainer ul li .MuiFormLabel-root.Mui-focused {
  color: #838384 !important;
}


.rowEllips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.noResult h2,
.noResult p {
  font-size: 20px;
  font-weight: bold;
  color: rgba(131, 131, 132, 0.43);
  margin-bottom: 10px;
  font-family: 'Varta', sans-serif;
}
.noResult h2 {
  text-transform: uppercase;
}
.noResult {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tenantUserListTable th.noSort:nth-last-child(3):after {
  display: none !important;
}
.tenantUserListTable th.noSort:nth-last-child(3) {
  pointer-events: none;
}
.listDescPrev {
  font-weight: normal;
}

.filterDropdown.filterDropdownCheck h4 {
  color: #838384;
  font-size: 11px;
  font-family: 'Varta', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}
.selectAllWrap h4 {
  margin-right: 10px;
}
.selectAllWrap .selectAllLoc{
  position: static !important;
}
.selectAllWrap button.selectAllLoc  span.MuiButton-label{
  margin-top: 0;
}
.selectAllWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.dlSwitch .MuiFormGroup-root{
  margin-top: 0;
}
.dlSwitch #dl28Label, .dlSwitch .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: Varta, sans-serif;
    font-weight: 600;
    color: #444444;
    font-size: 15px;
}
.dlSwitch .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  padding-top: 3px;
}
p#dl28Label {
  padding-left: 10px;
  padding-top: 14px;
  padding-right: 13px;
}
