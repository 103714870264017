.Background {
  min-height: 100vh;
  background: url(../../images/DL28_N210.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.Background:after {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.guestListCard {
  z-index: 1;
  position: relative;
  background: #fff;
  padding: 25px;
  width: 30%;
  min-width: 150px;
  border-radius: 6px;
}
.guestListCard .cardText {
  position: static;
  transform: none;
}
.cardText {
  text-align: left;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
.guestListCard .cardText h3 {
  color: #2a2a2a;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  font-size: 17px;
  line-height: 25px;
}
.cardText p,
.cardText ul li {
  color: #2a2a2a;
  font-size: 15px;
  font-weight: normal;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  line-height: 25px;
}

.guestlistSave {
  margin-top: 10px;
  color: white;
  background: black;
  width: 80px;
  height: 35px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  border-radius: 10px;
  font-size: 14px;
}
.d_none {
  display: none;
}
.d_block {
  display: block;
}
.SaveDisable {
  background-color: #ccc;
  border: 1px solid #ccc;
  color: darkgrey;
}

.color_red {
  color: red !important;
}
.mrt_n10 {
  margin-top: -10px;
}

.mrt_10 {
  margin-top: 10px;
}
.mrt_20 {
  margin-top: 20px;
}
@media only screen and (max-width: 1300px) {
  .guestListCard {
    width: 80vw;
    height: 11%;
  }

  .guestlistSave {
    margin-top: 15px;
  }
}
