.viewOfferDescription {
  color: #444444;
  width: 100%;
  border: solid 0.5px #979797;
  resize: none;
  outline: none;
  padding: 10px 15px;
  font-size: 14px;
  background: transparent;
  margin-top: 10px;
  font-family: 'Varta', sans-serif;
  line-height: 22px;
  border-radius: 3px;
  min-height: 65px;
}
.mouseClickCount span {
  font-family: 'Varta', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  color: #444444;
  margin-left: 10px;
}
.mouseClickCount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mouseClickCountHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.offerLocationBtn button,
.offerLocationBtn button:hover {
  color: #444444;
  box-shadow: none;
  background-color: #f8f8f8;
  cursor: not-allowed;
  pointer-events: none;
  font-size: 14px;
  font-family: 'Varta', sans-serif;
  font-weight: bold;
  border-radius: 5px;
}
.offerlocationFilterDropdown li {
  width: auto !important;
  position: relative;
  flex: none !important;
}
ul.MultipleImgUpload .MuiInputBase-root.Mui-disabled {
  pointer-events: none !important;
}
.offerListTable tbody tr td:last-child {
  vertical-align: middle;
}
tr .OfferType {
  text-transform: capitalize !important;
}
.image-uploading::before {
  border-top-color: #000 !important;
}

.offerListTable tbody tr td:last-child .formButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}

.offerListTable tbody tr td:last-child .formButton button.dna {
  background: #d9d9d9;
}

.offerListTable tbody tr td:last-child .formButton button {
  width: 40%;
  font-size: 14px;
}

.voucherCodeRadio legend.MuiFormLabel-root {
  font-family: "Varta",sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #838384;
  margin-bottom: 25px;
}

