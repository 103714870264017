.cardHeading {
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #3a3a3a;
  margin: 0 0 25px;
}
.cardrootMain .MuiAccordion-root {
  background: transparent !important;
  box-shadow: none;
  border-radius: 0 !important;
}
.cardrootMain .MuiAccordionSummary-root {
  padding: 0 0;
  min-height: auto;
  border-bottom: solid 2px #979797;
}
.cardrootMain .MuiAccordionSummary-content {
  margin: 0;
}
.cardrootMain .MuiIconButton-edgeEnd {
  margin-right: 0;
  padding: 0;
}
.cardrootMain .MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.cardrootMain .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.villageFilter {
  margin: 50px 0 30px;
}

.cardrootMain .MuiAccordionSummary-content h5 {
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
  color: #3a3a3a;
}
.cardrootMain .MuiAccordionSummary-content.Mui-expanded h5 {
  font-weight: 600;
}

.cardrootMain .MuiAccordionDetails-root {
  display: block;
  padding: 15px 20px;
  background: #f8f8f8;
  margin-top: 5px;
}

.selectedOfferBlock ul li .offerCardBox {
  border-radius: 3px;
  border: solid 1px #e4e4e4;
  min-height: 340px;
  padding: 12px 12px;
}
.selectedOfferBlock ul li:last-child {
  margin-right: 0;
}
.selectedOfferBlock ul li {
  width: 18%;
  margin-right: 30px;
}
.selectedOfferBlock ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.allOfferCardBlock {
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-top: 30px;
  padding: 0px 25px 10px;
}
.allOfferFilter {
  border-bottom: solid 1px #3a3a3a;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}

.allOffercard ul li:nth-child(6n) {
  margin-right: 0;
}
.allOffercard ul li {
  width: 14.8%;
  margin-right: 2%;
  margin-bottom: 35px;
  padding: 5px;
  cursor: pointer;
  border: 4px solid transparent;
  -webkit-box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%);
  margin-left: 2px;
  margin-top: 3px;
}

.allOffercard ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.publishDateMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 4px;
}
.offerDetails {
  margin-top: 7px;
}
.offerDetails h3 {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  font-family: 'Varta', sans-serif;
  color: #3a3a3a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.offerDetails h3:nth-child(2) {
  font-weight: 500;
}
.publishDate {
  font-size: 10px;
  font-family: 'Varta', sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a3a3a;
  width: 45%;
}
.publishType {
  font-size: 9px;
  font-family: 'Varta', sans-serif;
  font-weight: 500;
  color: #3a3a3a;
  border-radius: 3px;
  border: solid 0.5px #757373;
  padding: 0 4px;
  line-height: 17px;
  height: 16px;
  width: auto;
  max-width: 45%;
  text-align: center;
}
.allOffercard ul li img {
  margin: 0 auto;
}

.offerCardBtn button,
.offerCardBtn button:hover {
  font-size: 16px;
  font-weight: bold;
  line-height: 47px;
  letter-spacing: normal;
  color: #fff !important;
  font-family: 'Varta', sans-serif;
  height: 47px;
  padding: 0 45px;
  background: #000;
  text-transform: capitalize;
}
.offerCardBtn button:first-child {
  margin-right: 20px;
  background: transparent !important;
  color: #444444 !important;
}
.offerCardBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.offerlocationFilterDropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.selectedOfferBlock ul li .offerCardBox {
  position: relative;
  cursor: pointer;
}
.selectedOfferBlock ul li .offerCardBoxadd:after {
  position: absolute;
  content: '';
  background: url(../../images/buttons-large-plus.svg) no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
}

.allOffercard img {
  margin: 0 auto;
}
.removeOfferCard {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.offerfilterRgt .leftFilterIn li {
  border: none !important;
  margin-top: 0 !important;
  padding: 0 !important;
}
.offerfilterRgt .exportSerch li .serchFilter input,
.exportSerch li .serchFilter > div {
  height: 39px;
}
.offerfilterRgt .exportSerch li {
  padding: 11px;
}
.offerfilterRgt .exportSerch li img {
  width: 16px;
}
.offerfilterRgt .exportSerch li.searchExpanded {
  padding: 0;
}
.offerLogoBlock {
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
}
.offerfilterRgt .exportSerch li .serchFilter input {
  padding-left: 20px;
  background-color: transparent;
  border-bottom: solid 0.7px #3a3a3a;
  border-radius: 0;
  background-position: left center;
}

.offerFilterDropdown .moreListHead {
  width: 100%;
}

.offerNoresultPopup {
  width: 100%;
}
.offerNoresultPopup .noResult {
  position: static;
  margin: 0 auto;
  transform: none;
  display: block;
}
.allOffercard ul li:hover {
  border-color: #4e4e4e;
}
.allOffercard ul li.selected {
  border: 4px solid #4e4e4e;
}
.allOffercard ul li.disable {
  opacity: 0.35;
  cursor: not-allowed;
}
.allOffercard ul li.disable:hover {
  border-color: transparent;
}
.selectedOfferBlock ul li {
  position: relative;
}
.selectedOfferBlock .activeShape {
  display: none;
}
.selectedOfferBlock.opened .activeShape {
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
  z-index: 1;
}
.selectedOfferBlock li.shapesHide .activeShape {
  display: none;
}
.selectedOfferBlock li.shapesHide.opened .activeShape {
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
  z-index: 1;
}
.activeShape img {
  margin: 0 auto;
  width: 40px;
}

.allOfferCardBlock .allOffercard {
  max-height: 630px;
  overflow-y: auto;
  margin-bottom: 30px;
  position: relative;
  min-height: 90px;
  padding-right: 10px;
}
span.clearSearch img {
  display: block !important;
}

span.clearSearch {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}
.MuiCircularProgress-root.offerLoader.MuiCircularProgress-indeterminate {
  position: absolute;
}
.allOfferCardBlock {
  position: relative;
}

.pageContainer.tableContainer.offerCardManage {
  min-height: calc(100vh - 120px);
  position: relative;
  margin-bottom: 0;
  padding-bottom: 90px;
  max-width: 1540px;
  margin: 0 auto;
}

.offerFixedBtn {
  position: absolute;
  bottom: 10px;
  right: 30px;
}
.filterDropdown.filterDropdownCheck.offerFilterDropdownCheck .filterDropdownin {
  padding-bottom: 0;
}
.allOfferCardBlock .offerLogoBlock {
  min-height: 200px;
  max-height: 200px;
}

.villageFilter button span.MuiButton-label {
  margin-top: 0;
  width: auto;
}

.cardrootMain .MuiCollapse-container.MuiCollapse-entered {
  min-height: 90px !important;
  background: #f8f8f8;
}

.videoCardBlock .publishDate h3:last-child {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
}
.videoCardBlock .publishDate h3 {
  font-size: 11px;
}
.videoCardBlock .publishDateMain {
  margin-top: 0;
  width: 100%;
}

.videoCardBlock .offerLogoBlock video {
  height: 200px;
  width: 100%;
  object-fit: contain;
}
.offerCardBox.offerCardBoxadd video {
  width: 100%;
}

.videoCardBlock .offerDetails {
  min-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.allOfferFilter h3,
.offerCardBoxAdded li > h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: normal;
  color: #3a3a3a;
}
.offerCardBoxAdded li > h3 {
  margin-bottom: 8px;
}

.offerCardBtn.loopVideoBtn {
  margin-top: 20px;
}
