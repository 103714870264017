.createSpaceFetr li {
  width: 46.5% !important;
  margin-bottom: 0 !important;
}
.maxUpload {
  margin: 0 0 28px;
  opacity: 0.4;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
  color: #838384;
  width: 100%;
}
ul.MultipleImgUpload li {
  margin-bottom: 15px !important;
}
.imageAddIconUpload {
  position: relative;
  left: 0;
  top: 0;
  height: 85px;
  width: 100%;
  border: solid 1px rgba(151, 151, 151, 0.15);
  z-index: 1;
  overflow: hidden;
  display: block;
}
.imageAddIconUpload img {
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
ul.MultipleImgUpload {
  align-items: flex-start !important;
}
.spaceForm,
.createcategoryForm.spaceForm {
  width: 60% !important;
}
.spaceForm ul li {
  position: relative;
}
.spaceForm ul {
  align-items: flex-start !important;
}
.uploadsize {
  width:300px;
  font-size: 10px;
  font-weight: normal;
  color: #838384;
  text-align: left;
  margin: 5px 0 0;
  display: block;
}
.mrt_10{
  margin-top: 10px;
}
.videoPlayBox .imageAddIcon video {
  z-index: 1;
}
.ql-container.ql-snow {
  border: 1px solid #979797;
}
.ql-toolbar.ql-snow {
  border: 1px solid #979797;
}
li.mouseClickCountHeading span {
  font-size: 16px;
  font-weight: bold;
  color: #838384;
}
.spaceForm {
  width: 65% !important;
}

ul.MultipleImgUpload.imgUploadVideo.multiSelectUpload {
  width: 100% !important;
}

ul.MultipleImgUpload li input {
  cursor: pointer;
}
