/* .createcategoryForm {
    width: 45% !important;
  }
  .creaeCategoryField,
  .creaeCategoryField li {
    width: 100% !important;
  }
  input::-webkit-input-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  input::-moz-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  input:-ms-input-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  input:-moz-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  
  textarea::-webkit-input-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  textarea::-moz-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  textarea:-ms-input-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  textarea:-moz-placeholder {
    color: rgba(140, 140, 152, 0.8) !important;
  }
  
  .MuiPickersToolbar-toolbar {
    background: #000 !important;
  }
  .MuiDialogActions-root .MuiButton-textPrimary {
    color: #000 !important;
  }
  .MuiPickersClock-pin {
    background: #000 !important;
  }
  .MuiPickersClockPointer-pointer {
    background: #000 !important;
  }
  .MuiPickersClockPointer-noPoint {
    background: #000 !important;
  }
  .MuiPickersClockPointer-thumb {
    border: 14px solid #000 !important;
  }
  .MuiPickersDay-daySelected {
    background: #000 !important;
  }
  
  .MuiPickersDay-day {
    color: rgba(117, 113, 113, 0.8) !important;
  }
  .MuiPickersDay-current {
    color: #000 !important;
  }
  .MuiPickersDay-daySelected {
    color: #fff !important;
  }
  .MuiPickersYearSelection-container .MuiTypography-colorPrimary {
    color: #000 !important;
  }
  input[readonly],
  textarea[readonly] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .offerDtCat input[readonly],
  .offerDtCat textarea[readonly] {
    opacity: 1;
    cursor: pointer;
  }
  .offerDtCat.offerDtCatView input[readonly],
  .offerDtCat.offerDtCatView textarea[readonly],
  div[readonly] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  ul.MultipleImgUpload {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  ul.MultipleImgUpload li {
    margin-right: 7% !important;
  }
  ul.MultipleImgUpload li:last-child {
    margin-right: 0px !important;
  }
  .radioSection p {
    color: #838384;
    font-size: 16px;
    font-family: 'Varta', sans-serif;
    line-height: 20px;
  }
  .offerDtCat.offerDtCatView input {
    background: #fff !important;
  }
  .offerTimeCat input[readonly],
  .offerTimeCat textarea[readonly] {
    opacity: 1;
    cursor: pointer;
  }
  ul.offerTimeCat {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .creaeCategoryField .descriptionBox {
    top: 50px;
    bottom: auto;
  }
  .createcategoryForm li.radioSectionLabel p {
    margin-top: 10px;
    margin-left: 0;
  }
  .creaeCategoryField li > div > div {
    margin-top: 0 !important;
  }
  .toLowercase {
    text-transform: lowercase !important;
  }
  
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .creaeCategoryFieldTop li {
    width: 100% !important;
  }
  ul.MuiList-root.MuiMenu-list {
    max-height: 250px;
  }
  
  
  
  ul.offerDtCat.voucherDtCat li {
    width: 46% !important;
  }
  ul.offerDtCat.voucherDtCat {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  } */

  li.loyaltyCardFullW{
    width: 100% !important;
    position: relative;
  }
  .loyaltyCardPrev {
    background: #000;
    border-radius: 16px;
    padding: 14px;
    max-width: 338px;
}
.cardLogo ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cardLogo ul li {
  margin: 0 !important;  
  width: 38% !important;
}
.cardLogo ul li:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.cardLogo ul li:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}


.latantaCardDesc {
  margin: 20px 0;
}
.latantaCardDesc p {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
	font-family: 'Conv_AtlasGrotesk-Regular-App';
  max-height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
.latantaCupAdded ul li img {
  margin: 0 auto;
}
.latantaCupAdded ol li {
  width: 100% !important;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 0px 3px;
  height: 54px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
          margin-right: 10px;
          margin-bottom: 0 !important;
}

.latantaCupAdded ol li:last-child {
  margin-right: 0;
}
.latantaCupAdded ol{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.loyaltyCardPrev.viewLoyaltyCardPrev{
  pointer-events: none;
  opacity: 0.6 !important;
    cursor: not-allowed;
}

li.loyaltyCardFullW div:nth-child(3) > div ,
li.loyaltyCardFullW div:nth-child(3) > div .chrome-picker{
  width: 100% !important;
}
.colorPickerCard > div:after,
.colorPickerCard > div:before{
  display: none;
}
.colorPickerCard > div{
  width: 100%;
}

.ColorPicker-MuiFormControl-root.ColorPicker-MuiTextField-root > div:before,
.ColorPicker-MuiFormControl-root.ColorPicker-MuiTextField-root > div:after{
  display: none;
}
li.loyaltyCardFullW > div button:focus {
  box-shadow: none;
}
li.loyaltyCardFullW > div button {
  margin: 0 10px 0 0;
}
li.loyaltyCardFullW > div button span.MuiButton-label {
  margin: 0;
}
li.loyaltyCardFullW > div .ColorPicker-MuiFormControl-root.ColorPicker-MuiTextField-root {
  width: 100%;
}
li.loyaltyCardFullW > div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cardLogo img {
  max-height: 36px;
}
.max_height_100p{
  max-height: 100%;
}
/* .cardLogo {
  max-height: 36px;
  overflow: hidden;
} */