.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}

.addUserContainer .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.PrivateRadioButtonIcon .MuiSvgIcon-root {
  font-size: 16px;
}
.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}

.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.InputSectionMeeting li input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#StatusInAddMeetingRoom {
  margin-bottom: -30px;
}
#showOnMobileButtonArea {
  padding-top: 30px;
  padding-right: 100px;
}
#showOnMobileButtonName {
  padding-top: 13px;
}

th.MuiTableCell-root.MuiTableCell-head.tableHead {
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  /* font-weight: bold; */
  color: #838384;
}
tr.MuiTableRow-root.MuiTableRow-head.tableHeader {
  border-bottom: 2px solid #979797 !important;
}
.saveButtonForLayout {
  color: #fff !important;
  width: auto;
  border: 1px solid #000 !important;
  cursor: pointer !important;
  height: 47px !important;
  outline: none !important;
  padding: 0 45px !important;
  font-size: 16px !important;
  background: #000000 !important;
  font-family: Varta, sans-serif !important;
  font-weight: bold !important;
  line-height: normal !important;
}
.cancelBtnLayout {
  color: #000000 !important;
  /* border: 2px solid #000; */
  padding: 0 32px !important;
  background: transparent !important;
}
.layoutBrd {
  border: solid 1px rgba(151, 151, 151, 0.15);
  max-width: 70px;
  max-height: 56px;
  text-align: center;
  height: 85px;
  width: 100%;
  line-height: 85px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.addLayoutBtn {
  display: flex;
  justify-content: flex-start;
  padding: inherit;
  position: relative;
  padding-left: 16px;
}
.layoutNote {
  margin-left: 14px;
  opacity: 0.4;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
  color: #838384;
  width: 100%;
  /* line-height: 50px; */
}

.layoutBrd img {
  margin: 0 auto;
}
.d_none {
  display: noe;
}
.d_flex {
  display: flex;
}
.layoutBoxStyleEdit {
  border: solid 0.5px #979797;
  width: 100%;
  height: fit-content;
  padding: 20px;
  color: #444444;
  border-radius: 3px;
  margin-top: -15px;
}
.layoutImg {
  width: 100px;
  height: 70px;
  cursor: pointer;
}
