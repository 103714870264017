.rbc-allday-cell {
  display: none;
}
.rbc-header {
  padding: 0.8em;
  border: none;
}
.rbc-events-container {
  margin-left: 0.6em;
  margin-top: 0.4em;
  border: none;
}
.rbc-slot-selection {
  display: none;
}
.rbc-timeslot-group {
  padding-top: 0.9em;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}
.rbc-event-label {
  font-size: 14px;
  display: none;
}
.rbc-event {
  padding: 0.6em;
}
.datepicker-class {
  position: absolute;
  left: 83%;
  top: -0%;
}
.today-btn {
  position: absolute;
  right: 148%;
  top: 22%;
}
.location {
  position: absolute;
  right: 1000px;
}
.rightFilterPagination > div > div > p {
  color: #838384;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}
.pageContainer.filterContainer {
  margin-top: 0;
}
.pageContainer.filterContainer {
  margin-top: 0;
}
.custom-width {
  min-width: 469px;
}
.floatRight {
  float: right;
  padding-right: 20px;
}
.mainHeading {
  margin-bottom: 100px;
}
.equipmentCol {
  width: 50%;
  display: inline-block;
  padding-top: 8px;
}
.icon {
  font-size: 15px !important;
  opacity: 0.3;
  margin-left: 6px;
  position: relative;
  top: 2px;
}
.rbc-btn-group {
  position: absolute;
  right: 275px;
  top: -47px;
}

.rbc-btn-group button {
  border: none;
  margin-right: 5px;
}
.rbc-btn-group button:hover,
.rbc-btn-group button:active,
.rbc-btn-group button:focus {
  outline: none;
  background-color: transparent;
}
.rbc-toolbar-label {
  position: absolute;
  right: 44px;
  top: -45px;
  border-bottom: 1px solid #444;
  padding: 12px 10px 5px !important;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  min-width: 230px;
}
.calenderDatePicker > div {
  top: 8px;
}
.noBuilding {
  font-size: 18px;
  font-weight: 500;
  padding: 150px 20px;
  text-align: center;
  min-height: 62vh;
}
.prevArrow {
  font-size: 16px;
  color: #838384;
  font-weight: 500;
}
.locationName {
  font-size: 12px;
  font-weight: normal;
}
.rbc-toolbar .rbc-btn-group button {
  cursor: pointer;
  box-shadow: none;
  border-radius: 4px !important;
}
.rbc-toolbar .rbc-btn-group button.todayBtn {
  background-color: #f8f8f8;
  border-radius: 5px !important;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 600;
  padding: 6px 30px;
  text-transform: capitalize;
  color: #444444;
  font-family: 'Varta', sans-serif;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #f8f8f8;
  color: #444444;
}
.calenderContainer .leftCalenderFilterIn div ul li:last-child {
  border-bottom: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 0 !important;
}
.calenderContainer .leftCalenderFilterIn div ul li:last-child:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.calenderContainer .leftCalenderFilterIn div ul li:last-child img {
  margin: 0 auto;
  padding: 12px;
}

.bookedSlots {
  position: relative;
  padding: 10px;
}
.rbc-event .rbc-event-label {
  display: none;
}
.rbc-event {
  padding: 0 !important;
}

.rbc-events-container {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.bookedUserName {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;
  padding-right: 13px;
  border-bottom: 0.5px solid rgba(183, 183, 183, 0.5);
  padding-bottom: 5px;
  margin-bottom: 7px;
}
.bookedTenantName {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
}
.tooltipIcon {
  font-size: 15px !important;
  position: absolute;
  right: 6px;
  opacity: 0.6;
  top: 12px;
}
.tooltipIcon.tooltipIconUp {
  top: 3px;
}
.rbc-event-content {
  overflow: hidden;
  position: relative;
}
.calenderContainer,
.pageContainer.filterContainer.calenderContainer {
  padding-left: 0;
  padding-right: 0;
}
.buildingName {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: #444444;
  text-transform: uppercase;
  position: relative;
  top: 49px;
  left: -117px;
}
.pageContainer.addUserContainer.calenderContainer .rbc-toolbar {
  margin-bottom: 10px;
}
.rbc-calendar .rbc-time-view {
  border: none;
}
.meetingRoomTitle strong {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 75%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calenderDatePicker {
  width: 230px;
  position: absolute;
  right: 35px;
  bottom: -46px;
  z-index: 9;
  opacity: 0;
  height: 45px;
  cursor: pointer;
}

.calenderDatePicker input[readonly],
.calenderDatePicker textarea[readonly] {
  opacity: 1;
  cursor: pointer;
}
.tooltipBookedUser .bookedUserName {
  border-bottom: 0.5px solid #000;
}
.dotClass {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -10px;
  text-align: center;
}
.dotClass img {
  margin: 0 auto;
}
.meetingRoomTitle {
  position: relative;
}
.rbc-time-slot {
  flex: 0 0 0 !important;
  margin: 0 0 0 0px;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7 !important;
}
.rbc-day-slot .rbc-timeslot-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.rbc-day-slot .rbc-timeslot-group > .rbc-time-slot:first-child {
  display: none;
}
.rbc-events-container {
  margin-left: 0.6em;
  margin-top: 0em;
  border: none;
}
.rbc-current-time-indicator {
  background-color: #313131;
  height: 2px;
}
.rbc-day-slot .rbc-timeslot-group {
  cursor: pointer;
}
.bookedSlots p {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Varta', sans-serif;
}
.meetingRoomTitle svg {
  position: absolute;
}
.rbc-time-gutter.rbc-time-column .rbc-time-slot {
  margin-top: -13px;
  background: #fff;
  font-size: 15px;
  font-weight: 600;
}
.tooltipBHeading {
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 20px !important;
  font-family: 'Varta', sans-serif !important;
  color: #444444 !important;
  margin: 0 0 2px 0 !important;
  text-transform: capitalize !important;
}
.tooltipBSubHeading,
.equipmentCol {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  font-family: 'Varta', sans-serif !important;
  color: #444444 !important;
  margin: 0 0 20px 0 !important;
  text-transform: capitalize !important;
}
.tooltipBSubHeading.tooltipsDesc{
  text-transform: initial !important;
}
.tooltipBSubHeading.equipmentCol {
  display: block;
  margin: -5px 0 7px 0 !important;
}
.tooltipBLine {
  margin: -10px auto 10px !important;
  display: block !important;
}
.tooltipBHeadingCapital {
  text-transform: capitalize !important;
}
.tooltipBHeadingFlex {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.mainHeading.tooltipBHeading {
  margin: 0 0 20px 0 !important;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: 191px;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 190px;
}
.mrFilter li button.buildSelectedBtn {
  padding: 7px 12px 8px !important;
}
.MuiTablePagination-actions button.MuiIconButton-root.Mui-disabled {
  color: #000 !important;
}

table tbody td .Cancelled {
  color: #f90000 !important;
}
table tbody td .Booked {
  color: #095967 !important;
}
table tbody td .NoShow {
  color: #b8b8b9 !important;
}
table tbody td .Booked.Pending {
  color: #CE0000 !important;
}

.extendBookingTimeLeft {
  display: none;
}
.bookedTenantName.blockedTenantName button {
  color: #fff;
  padding: 0 15px;
  font-size: 14px;
  box-shadow: none;
  font-family: Varta,sans-serif;
  font-weight: 600;
  border-radius: 5px;
  background-color: #000;
  height: 26px;
  line-height: 26px;
  border-radius: 20px;
  cursor: pointer;
  margin-top:-3px;
}
.bookedTenantName.blockedTenantName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 20px;
}
.bookedSlots.pd_0_10px{
  padding: 0px 10px;
}
.bookedSlots.pd_0_10px .tooltipIcon{
  top:3px
}
.rbc-event {
  width: 100% !important;
  left: 0 !important;
}