.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}

.pageContainer.addUserContainer {
  margin-top: 0;
  position: relative;
}

.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}

.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}
.ditIcon {
  position: absolute;
  right: 4%;
  top: 38px;
  cursor: pointer;
}

.comboBoxSerch > div > div {
  color: #444444;
  border: solid 0.5px #979797;
  height: 45px;
  padding: 0 0 !important;
  font-size: 16px;
  background: transparent url(../../images/down-arw.svg) no-repeat 95% center !important;
  margin-top: 16px;
  font-family: 'Varta', sans-serif;
  line-height: 45px;
  border-radius: 3px;
  background-size: 10px;
  -webkit-appearance: none;
  display: block;
  outline: none;
  box-sizing: content-box;
}
.comboBoxSerch > div > div input {
  width: 100% !important;
  height: 100%;
  padding: 0 15px !important;
  box-sizing: border-box;
  cursor: pointer;
}

.MuiDivider-light {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MultipleImgUpload .imageAddName {
  text-align: left;
}
.offerImgUoload li:last-child {
  margin-bottom: 0;
}
.offerImgUoload .uploadsize {
  text-align: left;
}
.MultipleImgUpload li {
  margin-bottom: 0 !important;
}
span.imageAddIcon video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.MultipleImgUpload li div.Mui-disabled + span {
  pointer-events: none;
}
.imageAddIcon.videoAddIcon {
  z-index: 99;
}
.ql-editor ul {
  display: block !important;
  margin: 0 !important;
}
.viewOfferDescription ul {
  display: block !important;
  list-style: disc !important;
  margin: 0 0 0 15px !important;
}
.viewOfferDescription ul li {
  list-style: disc !important;
}

.descriptionBox.nbDescBox {
  top: 50px;
  bottom: auto;
}

.MultipleImgUploadWrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.richTextEditor {
  margin-top: 10px;
}
.ql-container {
  min-height: 200px;
  max-height: 700px;
  overflow-y: auto;
}
textarea {
  resize: none !important;
}

.selectAllLoc {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  font-size: 14px !important;
  outline: none !important;
  text-transform: inherit !important;
  color: #838384 !important;
  font-family: 'Varta', sans-serif !important;
  text-decoration: underline !important;
  position: absolute !important;
  right: 30px !important;
  top: 20px !important;
}

.ql-editor span,
.ql-editor strong,
.ql-editor em,
.ql-editor u,
.ql-editor a {
  line-height: normal;
}

ul.multiMediaSelect li label {
  font-family: 'Varta', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #838384;
}
ul.multiMediaSelect li .radioSection span {
  font-weight: bold;
}
ul.multiMediaSelect li > fieldset {
  margin-bottom: 30px;
}
.multiMediaSelect ul.MultipleImgUpload {
  width: 100%;
}
ul.multiMediaSelect {
  min-height: 250px;
}


ul.announcementMediaOnly {
  display: block !important;
}
.offerlocationFilterDropdown.newsArticleFilterDropdown > li {
  width: 50% !important;
}
.offerlocationFilterDropdown.newsArticleFilterDropdown > li > li{
  display: inline-block !important;
}
.offerlocationFilterDropdown.newsArticleFilterDropdown {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
li.allLocationsTenantsSelectionCheck  .MuiCheckbox-colorSecondary.Mui-checked {
  color: #000;
}
.pageContainer 
 li.allLocationsTenantsSelectionCheck span {
    text-transform: initial !important;
    color: #444444;
    position: relative;
    font-size: 16px;
    font-family: 'Varta', sans-serif;
    line-height: normal;
}
ul.offerlocationFilterDropdown.newsArticleFilterDropdown > li:first-child {
  margin-bottom: 0;
}

button.all-tenant-selectedButton {
  color: #fff !important;
  background: #000 !important;
}
.pageContainer ul.offerDtCat.eventDtBox li label {
  text-transform: initial !important;
}