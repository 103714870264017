.twoRowEllips {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.createcategoryForm ul li {
  position: relative;
}
.radioSectionLabel span {
  color: #838384;
  display: block;
  font-size: 16px;
  font-weight: normal;
}
.createcategoryForm li.radioSectionLabel {
  width: 100% !important;
}
.dietrySelectBox .offerfilterRgt .leftFilterIn li {
  width: auto !important;
  margin: 0;
}
ul.dietrySelectBox li > span {
  font-size: 16px;
  font-weight: bold;
  color: #838384;
  margin-bottom: 20px;
  display: block;
  font-family: 'Varta', sans-serif;
  text-transform: capitalize !important;
}
ul.nutritionalInfoSection {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}
