ul.MultipleImgUpload.imgUploadVideo {
  position: relative;
}
.CropImgContainer {
  margin: 30px 0 0;
}
.cropPopupCont.cropPopupEditCont .CropImgContainer {
  width: 84%;
  margin: 0 auto 0;
  max-height: 65vh;
  overflow-y: auto;
}
.cropPopupCont h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #3a3a3a;
  border-bottom: solid 1px #979797;
  padding-bottom: 5px;
}
.cropImgName h4 {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #3a3a3a;
}
.cropImgName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 2px;
}
.cropImgBtn {
  text-align: right;
  margin-top: 20px;
}
.cropImgBtn button {
  color: #fff;
  width: auto;
  border: 1px solid #000;
  cursor: pointer;
  height: 47px;
  outline: none;
  padding: 0 45px;
  font-size: 16px;
  background: #000000;
  font-family: Varta, sans-serif;
  font-weight: bold;
  line-height: 47px;
  margin-left: 0;
  text-transform: capitalize;
}
.cropImgBtn button:hover {
  background: #000000;
}

.cropFooterBtn button {
  color: #fff;
  width: auto;
  border: none;
  outline: none;
  cursor: pointer;
  height: 47px;
  outline: none;
  padding: 0 45px;
  font-size: 16px;
  background: #000000;
  font-family: Varta, sans-serif;
  font-weight: bold;
  line-height: 47px;
  box-shadow: none !important;
  text-transform: capitalize;
}

.cropFooterBtn button span {
  margin: 0;
}
.cropFooterBtn button:hover {
  background: #000000;
}
.cropFooterBtn button:first-child {
  margin-right: 15px;
  background: transparent !important;
  color: #000000;
}
.cropFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
  border-top: solid 1px #979797;
  margin-top: 0;
}

.cropFooterBtn {
  width: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cropImgName img {
  cursor: pointer;
}

.reactEasyCrop_CropArea {
  color: rgb(255 255 255 / 51%) !important;
  border: solid 5px #3a3a3a !important;
}

.cropFooterSlide span.MuiSlider-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin-right: 0;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cropFooterSlide .MuiSlider-rail {
  width: 100%;
  height: 4px;
  display: block;
  opacity: 1;
  position: absolute;
  border-radius: 1px;
  background-color: #d7d9dd;
}
.cropFooterSlide .MuiSlider-thumb {
  width: 16px;
  height: 28px;
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  margin-top: 0;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  margin-left: -10px;
  border-radius: 2px;
  justify-content: center;
  background-color: currentColor;
  z-index: 2;
}
.cropFooterSlide .MuiSlider-thumb.Mui-focusVisible,
.cropFooterSlide .MuiSlider-thumb:hover {
  box-shadow: none;
}
.cropFooterSlide .MuiSlider-track {
  height: 4px;
  display: block;
}

.cropFooterSlide button span.MuiButton-label {
  margin: 0;
}
.cropFooterSlide button {
  /* font-size: 24px; */
  padding: 0;
  height: 50px;
  line-height: 50px;
  margin: 0 10px;
  min-width: 40px;
}
.cropFooterSlide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cropFooterSlide {
  width: 40%;
}
.cropFooterSlide button.btnDisable {
  opacity: 0.15;
  cursor: not-allowed;
}
.cropImgHW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  top: 0;
  position: absolute;
  right: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #3a3a3a;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.MuiSlider-root {
  color: #000 !important;
}
.cropPopupCont.cropPopupEditCont {
  position: relative;
}
