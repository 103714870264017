.MuiMenu-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09) !important;
  border: solid 1px #fafafa !important;
  background-color: #fafafa !important;
}
.MuiMenu-paper ul li {
  color: #444444;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
}

.addUserContainer .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}
.PrivateRadioButtonIcon .MuiSvgIcon-root {
  font-size: 16px;
}
.addUserContainer .MuiButton-root.Mui-disabled,
.addUserContainer .MuiButton-root:hover.Mui-disabled {
  color: #cacaca;
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  pointer-events: all;
}
.cancelDialogOpend .MuiDialog-root {
  z-index: 9999 !important;
}
.cancelDialogOpend .MuiDialog-paper {
  padding: 82px 20px 20px;
  width: 38%;
  border-radius: 0;
}
.cancelDialogOpend.pb_30 .MuiDialog-paper  {
  padding:30px;
}
.mb_22{
  margin-bottom: 22px;
}
.Mui-disabled,
.MuiInputBase-input.Mui-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.formDivider {
  background-color: rgba(233, 233, 233, 0.8) !important;
  margin: 22px auto 22px !important;
  width: 80%;
}
.popupFormButton {
  margin-top: 0 !important;
}
li.MuiBreadcrumbs-separator {
  top: -2px;
  position: relative;
}

.radioSection span:last-child {
  margin-top: 4px !important;
  margin-left: -4px !important;
}
.creaeCategoryFieldTop li > span {
  margin-bottom: 3px;
}
.radioSection .MuiRadio-root {
  padding: 0px 9px !important;
}
.lineformButton {
  margin-top: 0px !important;
}
@media only screen and (min-width: 1024px) {
  .cancelDialogOpend.width_45p .MuiDialog-paper{
    width: 45%;
  }
}
@media only screen and (max-width: 1024px) {
  .cancelDialogOpend.width_100p .MuiDialog-paper{
    width: 100%;
  }
}