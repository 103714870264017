html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

li {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-family: 'Varta', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pageContainer {
  height: 100%;
  width: 100%;
  margin-top: 76px;
  padding: 12px 30px;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 20px;
  border: 3px solid #f8f8f8;
}

@font-face {
  font-family: 'Conv_AtlasGroteskApp-Light';
  src: url('./fonts/AtlasGroteskApp-Light.eot');
  src: local('☺'), url('./fonts/AtlasGroteskApp-Light.woff') format('woff'),
    url('./fonts/AtlasGroteskApp-Light.ttf') format('truetype'),
    url('./fonts/AtlasGroteskApp-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  src: url('./fonts/AtlasGrotesk-Regular-App.eot');
  src: local('☺'), url('./fonts/AtlasGrotesk-Regular-App.woff') format('woff'),
    url('./fonts/AtlasGrotesk-Regular-App.ttf') format('truetype'),
    url('./fonts/AtlasGrotesk-Regular-App.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_AtlasGroteskApp-Medium';
  src: url('./fonts/AtlasGroteskApp-Medium.eot');
  src: local('☺'), url('./fonts/AtlasGroteskApp-Medium.woff') format('woff'),
    url('./fonts/AtlasGroteskApp-Medium.ttf') format('truetype'),
    url('./fonts/AtlasGroteskApp-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_AtlasGrotesk-Bold-App';
  src: url('./fonts/AtlasGrotesk-Bold-App.eot');
  src: local('☺'), url('./fonts/AtlasGrotesk-Bold-App.woff') format('woff'),
    url('./fonts/AtlasGrotesk-Bold-App.ttf') format('truetype'),
    url('./fonts/AtlasGrotesk-Bold-App.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_JohnstonITCPro-Light';
  src: url('./fonts/JohnstonITCPro-Light.eot');
  src: local('☺'), url('./fonts/JohnstonITCPro-Light.woff') format('woff'),
    url('./fonts/JohnstonITCPro-Light.ttf') format('truetype'),
    url('./fonts/JohnstonITCPro-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_ArtCompanyMono-Light';
  src: url('./fonts/ArtCompanyMono-Light.eot');
  src: local('☺'), url('./fonts/ArtCompanyMono-Light.woff') format('woff'),
    url('./fonts/ArtCompanyMono-Light.ttf') format('truetype'),
    url('./fonts/ArtCompanyMono-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
