.createcategoryForm {
  width: 45% !important;
}
.creaeCategoryField,
.creaeCategoryField li {
  width: 100% !important;
}
input::-webkit-input-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
input::-moz-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
input:-ms-input-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
input:-moz-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}

textarea::-webkit-input-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
textarea::-moz-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
textarea:-ms-input-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}
textarea:-moz-placeholder {
  color: rgba(140, 140, 152, 0.8) !important;
}

.MuiPickersToolbar-toolbar {
  background: #000 !important;
}
.MuiDialogActions-root .MuiButton-textPrimary {
  color: #000 !important;
}
.MuiPickersClock-pin {
  background: #000 !important;
}
.MuiPickersClockPointer-pointer {
  background: #000 !important;
}
.MuiPickersClockPointer-noPoint {
  background: #000 !important;
}
.MuiPickersClockPointer-thumb {
  border: 14px solid #000 !important;
}
.MuiPickersDay-daySelected {
  background: #000 !important;
}

.MuiPickersDay-day {
  color: rgba(117, 113, 113, 0.8) !important;
}
.MuiPickersDay-current {
  color: #000 !important;
}
.MuiPickersDay-daySelected {
  color: #fff !important;
}
.MuiPickersYearSelection-container .MuiTypography-colorPrimary {
  color: #000 !important;
}
input[readonly],
textarea[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}
.offerDtCat input[readonly],
.offerDtCat textarea[readonly] {
  opacity: 1;
  cursor: pointer;
}
.offerDtCat.offerDtCatView input[readonly],
.offerDtCat.offerDtCatView textarea[readonly],
div[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}
ul.MultipleImgUpload {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
ul.MultipleImgUpload li {
  margin-right: 7% !important;
}
ul.MultipleImgUpload li:last-child {
  margin-right: 0px !important;
}
.radioSection p {
  color: #838384;
  font-size: 16px;
  font-family: 'Varta', sans-serif;
  line-height: 20px;
}
.offerDtCat.offerDtCatView input {
  background: #fff !important;
}
.offerTimeCat input[readonly],
.offerTimeCat textarea[readonly] {
  opacity: 1;
  cursor: pointer;
}
ul.offerTimeCat {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.creaeCategoryField .descriptionBox {
  top: 50px;
  bottom: auto;
}
.createcategoryForm li.radioSectionLabel p {
  margin-top: 10px;
  margin-left: 0;
}
.creaeCategoryField li > div > div {
  margin-top: 0 !important;
}
.toLowercase {
  text-transform: lowercase !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.creaeCategoryFieldTop li {
  width: 100% !important;
}
ul.MuiList-root.MuiMenu-list {
  max-height: 250px;
}



ul.offerDtCat.voucherDtCat li {
  width: 46% !important;
}
ul.offerDtCat.voucherDtCat {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}