.cropBtn span {
  margin: 0 !important;
  padding: 0 !important;
}
button.MuiButton-root.cropBtn {
  background: #f1f3f4;
  height: 35px;
  line-height: 34px;
  padding: 0;
  margin: 7px auto 0;
  display: block;
  width: 95px;
  font-size: 14px;
  color: #444444;
  font-weight: 600;
  font-family: 'Varta', sans-serif;
}
.cropPopUp
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: 65%;
}
.CropImgContainer ul {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.CropImgContainer.CropImgCateringMenu ul {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
.CropImgContainer ul li:first-child {
  width: 35% !important;
  margin-left: 0;
}
.CropImgContainer ul li {
  width: 25% !important;
  margin-right: 5% !important;
}
.CropImgContainer ul li:last-child {
  margin-right: 0 !important;
}

.cropPopupContainer {
  position: absolute;
  background: #fff;
  width: 100%;
  top: 160px;
  z-index: 222;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19);
  border: solid 0.2px rgb(151 151 151 / 19%);
}
.reactEasyCrop_Container {
  position: relative !important;
}
.reactEasyCrop_Contain {
  position: static !important;
}
.cropPopupContainer.cropPopupContainerScndRow {
  top: 340px;
}

.spaceFileContainer {
  position: relative;
}

.cropPopupContainer {
  position: fixed;
  background: #fff;
  width: 65%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 222;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 19%);
  border: solid 0.2px rgb(151 151 151 / 19%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999;
}
body.modalOpen {
  overflow: hidden;
}
body.modalOpen:after {
  position: fixed;
  left: 0;
  top: 0;
  background: #00000052;
  content: '';
  width: 100%;
  height: 100%;
  z-index: 999999;
}
.cropPopupContainer.cropPopupContainerEdit {
  width: 55%;
}
