#paymentContainer iframe {
    width: 100%;
}

#paymentResult,
#paymentContainer,
#recurring-paymentContainer,
#recurring-payment {
    padding: 5px;
}

#errors li {
    color: #B00;
}

iframe.threeDs {
    width: 400px;
    height: 400px;
    margin: 100px 0 0 -175px;
    position: fixed;
    top: 0;
    left: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    background-color: white;
}

iframe.threeDs {
    z-index: 2;
}

label {
    line-height: 24px;
    color: #2A2A2A;
    font-family: 'Conv_AtlasGrotesk-Regular-App' !important;
}