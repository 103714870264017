.BuildingDetails li {
  width: 66% !important;
}
.BuildingDetails li:first-child {
  width: 100% !important;
}
.BuildingLocations {
  display: block !important;
}
.BuildingDesc {
  padding-top: 15px;
}
.BuildingVilgLocations {
  padding-bottom: 15px;
}
.BuildingDesc li:first-child {
  width: 100% !important;
}
.AgentDetails li:last-child {
  width: 66% !important;
}
.formButton button {
  color: #fff;
  width: auto;
  text-transform: capitalize;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  height: 35px;
  outline: none;
  padding: 0 30px;
  font-size: 15px;
  background: #000000;
  font-weight: 600;
  line-height: 35px;
  font-family: 'Varta', sans-serif;
  margin: 0 auto;
  min-width: auto;
  letter-spacing: 0;
  border-radius: 2px;
}
.formButton button:hover {
  background: #000000;
}
.ptaCkeckbox span svg {
  fill: #979797;
  font-size: 19px;
}
.ptaCkeckbox .Mui-checked svg,
.ptaCkeckbox .Mui-checked {
  fill: #000000;
}
.ptaCkeckbox {
  text-align: center;
}
tr .ManageType {
  text-transform: capitalize !important;
}
.textCapitalize {
  text-transform: capitalize !important;
}
/* Remove scroll/spinner for number input */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

